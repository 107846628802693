import React from 'react'
import { Container } from 'react-bootstrap'
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom'
import purify from 'dompurify'
import { useCookies } from 'react-cookie'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { useSnackbar } from 'notistack'

import useCommentWritter from './hooks/useCommentWritter'
import CommunityLayout from '../../layouts/communityLayout'
import CommentWrapper from './components/commentWrapper'
import AppContext from '../../services/context'
import backIcon from '../../assets/icons/icon-outlined-directional-left-white.svg'
import { formatDate } from '../../services/utils'
import {
  postCommunityComment,
  deleteCommunityPost,
  // testRecaptcha
} from '../../services/api'
//
import cmntIcon from '../../assets/icons/icon-filled-application-comment-26-px-primary.svg'
import usePasswordModal from './hooks/usePasswordModal'
//
const PostPage = () => {
  const contentRef = React.useRef()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const [cookies] = useCookies(['jwt_token'])
  const { appState } = React.useContext(AppContext)
  const post = appState.currentPost
  const [writter, renderWritterInput, initWritterInput] = useCommentWritter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { enqueueSnackbar } = useSnackbar()
  //
  const [commentList, setCommentList] = React.useState(post.comment)
  const [comment, setComment] = React.useState('')
  //
  const date = new Date(post.createdAt)
  //
  React.useEffect(() => {
    const preventEvent = (e) => {
      window.alert('복사를 허용하지 않습니다.')
      e.preventDefault()
      e.clipboardData.clearData('Text') // 클립보드에 저장된 컨텐츠 삭제
    }
    window.addEventListener('copy', preventEvent)
    return () => {
      window.removeEventListener('copy', preventEvent)
    }
  }, [])
  //
  const onInputChange = async (e) => {
    await setComment(e.target.value)
  }
  //
  const { open, renderPasswordModal } = usePasswordModal()
  //
  const onClickDelete = async () => {
    if (!post.user) {
      open(post, async (postId, code) => {
        if (code === 'SUCCESS') {
          await deleteCommunityPost(
            post.user,
            params.boardId,
            post._id,
          )
          history.replace(`/board/${params.boardId}`)
        } else {
          enqueueSnackbar('비밀번호가 맞지 않습니다', { variant: 'error' })
        }
      })
      return
    }
    await deleteCommunityPost(
      post.user,
      params.boardId,
      post._id,
    )
    history.replace(`/board/${params.boardId}`)
  }
  const onCommentClick = React.useCallback(
    async () => {
      if (!comment) return
      let result = null
      if (appState.userInfo === null) {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available')
          return
        }
        const token = await executeRecaptcha('comment')
        result = await postCommunityComment(
          null,
          params.boardId,
          post._id,
          comment,
          token,
          writter,
        )
        initWritterInput()
      } else {
        result = await postCommunityComment(
          cookies.jwt_token,
          params.boardId,
          post._id,
          comment,
        )
      }
      if (!result) return
      result.user = {
        _id: result.user,
        userId: appState.userInfo?.userId,
      }
      await setCommentList((prev) => prev.concat([result]))
      await setComment('')
    },
    [
      executeRecaptcha,
      appState.userInfo,
      comment,
      cookies.jwt_token,
      writter,
      post._id,
      initWritterInput,
    ],
  )
  //
  const onClickDeleteComment = React.useCallback(
    (commentId) => {
      setCommentList((prev) => prev.filter((item) => item._id !== commentId))
    },
    [],
  )
  //
  React.useEffect(() => {
    const contents = purify.sanitize(post.contents)
    contentRef.current.innerHTML = contents
  }, [contentRef, post.contents])
  //
  return (
    <CommunityLayout>
      <Container className="mb-6">
        <div className="post-section mt-4">
          <div className="hidden lg:block post-header">
            <h2>{post.title}</h2>
            <div className="flex flex-row details">
              <span className="author-label">글쓴이</span>
              <span className="author">{post.name}</span>
              <div className="flex-grow" />
              <span className="date-label">작성일</span>
              <span className="date">{formatDate(date, '.')}</span>
            </div>
          </div>
          <div className="flex flex-col lg:hidden post-header">
            {(!post.user || post.user === appState.userInfo?.id) && (
              <div className="flex self-end">
                <div onClick={() => {
                  if (!post.user) {
                    open(post, (postId, code) => {
                      if (code === 'SUCCESS') {
                        history.push(`/board/${params.boardId}/edit`, post)
                      } else {
                        enqueueSnackbar('비밀번호가 맞지 않습니다', { variant: 'error' })
                      }
                    })
                    return
                  }
                  history.push(`/board/${params.boardId}/edit`, post)
                }}
                >
                  <span style={{ color: 'var(--text-secondary)' }}>수정</span>
                </div>
                <span className="btn-delete" onClick={onClickDelete}>
                  삭제
                </span>
              </div>
            )}
            <h2>{post.title}</h2>
            <div className="flex flex-row justify-between">
              <span className="author">{post.user?.userId}</span>
              <span className="date">{formatDate(date, '.')}</span>
            </div>
          </div>
          <div className="divider-tertiary" style={{ marginBottom: 0 }} />
          <div className="post-container">
            {post.videoUrl && (
              <div className="video">
                <video
                  className="w-full"
                  src={post.videoUrl}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  playsInline
                  controls
                  autoPlay
                />
              </div>
            )}
            <div ref={contentRef} className="post-content" />
            <div className="hidden lg:block post-footer">
              <div className="reactions flex justify-end">
                <div className="flex">
                  {(!post.user || post.user === appState.userInfo?.id) && (
                    <>
                      <button
                        className="large border"
                        style={{ color: 'var(--text-secondary)' }}
                        onClick={() => {
                          if (!post.user) {
                            open(post, (postId, code) => {
                              if (code === 'SUCCESS') {
                                history.push(`/board/${params.boardId}/edit`, post)
                              } else {
                                enqueueSnackbar('비밀번호가 맞지 않습니다', { variant: 'error' })
                              }
                            })
                            return
                          }
                          history.push(`/board/${params.boardId}/edit`, post)
                        }}
                      >
                        수정하기
                      </button>
                      <button
                        className="large border btn-delete"
                        onClick={onClickDelete}
                      >
                        삭제하기
                      </button>
                    </>
                  )}
                </div>
              </div>
              {/* <div className="divider-tertiary" />
              {!appState.userInfo?.userId ? (
                renderWritterInput()
              ) : (
                <div className="commenter-id">{appState.userInfo?.userId}</div>
              )}
              <div className="textbox-action flex flex-row">
                <textarea
                  onChange={onInputChange}
                  className="flex-grow large"
                  placeholder="댓글을 남겨주세요."
                  value={comment}
                  rows={1}
                />
                <button
                  className="large dark"
                  onClick={onCommentClick}
                  disabled={comment === ''}
                >
                  등록
                </button>
              </div>
              <div className="comments-section-header">
                <span>{`댓글 수 (${commentList.length})`}</span>
              </div>
              <div className="comments-section">
                {commentList
                  && commentList.map((item, index) => (
                    <CommentWrapper
                      key={index}
                      commentData={item}
                      postId={post._id}
                      deleteCallback={onClickDeleteComment}
                    />
                  ))}
              </div>
              {commentList.length === 0 && (
                <div className="comments-section-empty">
                  <span>댓글이 없습니다.</span>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full button-wrapper">
          <button
            className="primary larger right-icon"
            onClick={() => history.replace(`/board/${params.boardId}`, { currentPage: location.state?.currentPage })}
          >
            <img src={backIcon} alt="go-back" style={{ marginRight: 10 }} />
            <span>목록으로</span>
          </button>
        </div>
        <div className="flex mobile-button-wrapper lg:hidden">
          {/* <button
            className="flex items-center reaction-button"
            onClick={onLikeClick}
            style={{ marginRight: 14 }}
          >
            <img src={liked ? mobileLikedIcon : notLikedIcon} alt="like" />
            <span>{`좋아요 ${snippetData.likeCnt}`}</span>
          </button> */}
          <Link
            to={
              {
                pathname: `/board/${params.boardId}/post/${post._id}/comment`,
                state: {
                  _id: post._id,
                  comment: commentList,
                },
              }
            }
            className="flex items-center reaction-button"
          >
            <img src={cmntIcon} alt="cmnt" />
            <span>{`댓글 수 ${commentList.length}`}</span>
          </Link>
        </div>
        {renderPasswordModal()}
      </Container>
    </CommunityLayout>
  )
}

const CaptchaWrapped = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeJdeAcAAAAAHBYBocoZ5czU4gCIWoLPx9KWbtS"
    useEnterprise
  >
    <PostPage />
  </GoogleReCaptchaProvider>
)

export default CaptchaWrapped
