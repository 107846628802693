import React from 'react'
import { Modal } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { getAssets } from '../../../services/api'
import emptyMedia from '../../../assets/icons/empty-media.svg'
import { assetsMenu } from '../../../services/menus'

import Menu from '../../../components/menu'

const SelectAssetModal = ({ isShown, onClose, onAssetSelect }) => {
  const [data, setData] = React.useState([])
  const [shownAssets, setShownAssets] = React.useState([])
  const [cookies] = useCookies(['jwt_token'])
  const [activeLink, setActiveLink] = React.useState(1)
  const [selectedAsset, setSelectedAsset] = React.useState({})
  //
  React.useEffect(() => {
    (async () => {
      const result = await getAssets(cookies.jwt_token)
      if (!result) return
      await setData(result)
    })()
  }, [cookies.jwt_token])
  //
  const onAction = async (e, id) => {
    e.preventDefault()
    await setActiveLink(id)
  }
  //
  React.useEffect(() => {
    (async () => {
      const ls = data.filter(
        (item) => item.type === activeLink && item.vidoeUrl !== null,
      )
      await setShownAssets(ls)
    })()
  }, [activeLink, data])
  //
  return (
    <Modal
      show={isShown}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="asset-selector">
          <div className="assets-header">
            <span>소재 불러오기</span>
          </div>
          <div className="assets-grid flex md:flex-row">
            <div className="flex flex-col" style={{ minWidth: 200 }}>
              <Menu
                items={assetsMenu}
                onAction={onAction}
                activeLink={activeLink}
              />
            </div>
            <div className="flex-grow grid md:grid-cols-2 gap-4 p-4 assets-holder">
              {shownAssets.map((item, index) => (
                <button
                  key={index}
                  className={
                    `asset-action p-2${
                      selectedAsset._id === item._id ? ' active' : ''}`
                  }
                  onClick={() => setSelectedAsset(item)}
                >
                  <video
                    src={item.vidoeUrl}
                    alt="asset"
                    controls
                    muted
                    poster={item.vidoeUrl && emptyMedia}
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <span className="tem-name">{item.assetName}</span>
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-row-reverse actions-section">
            <button
              className="dark large"
              onClick={(e) => onAssetSelect(e, selectedAsset)}
            >
              불러오기
            </button>
            <button onClick={onClose} className="light large">
              닫기
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SelectAssetModal
