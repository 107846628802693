import React from 'react'
import {
  Navbar, Container, Nav, ProgressBar,
} from 'react-bootstrap'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import Footer from '../../components/footer'
import VideoCarousel from '../../components/videoCarousal'
import {
  getProgress, fetchRenderInfos, patchCampaign, patchAssetDone,
} from '../../services/api'
import AppContext from '../../services/context'

const ResultPage = () => {
  const [state, setState] = React.useState(false)
  // const [content, setContent] = React.useState({})
  const [redirect, setRedirect] = React.useState(false)

  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const onComplete = async (data) => {
    await patchAssetDone(data?.asset?.id, data?.videoUrl)
    await setState(true)
    // await setContent({ ...data?.asset, videoUrl: data.videoUrl })

    const result = await patchCampaign(
      cookies.jwt_token,
      appState.adCampaign.id,
      { assetId: data?.asset?.id },
      2,
    )
    if (!result) return
    await appDispatch({
      type: 'updateAdCampaign',
      payload: result,
    })
    history.push('/portal/media/voice', { assetId: data.asset?.id })
  }
  //
  return redirect ? (
    <Redirect to="/portal/media" />
  ) : (
    <>
      <Navbar>
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="go-back" onClick={() => setRedirect(true)}>
              나의광고 로 돌아가기
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <main className="flex flex-grow flex-col ">
        <Container>
          <div className="ad-campaign-result flex flex-col items-center">
            <div className="header-section text-center w-full">
              <span>{`${state ? '렌더링이 완료되었습니다' : '렌더링을 시작합니다'}`}</span>
            </div>
            {/* {!state && <LoadingContent onComplete={onComplete} />}
            {state && <DoneContent data={content} />} */}
            <LoadingContent onComplete={onComplete} />
          </div>
        </Container>
      </main>
      <Footer />
    </>
  )
}

let intervalHandler = null

export const LoadingContent = ({ onComplete }) => {
  const location = useLocation()
  const [cookies] = useCookies(['jwt_token'])
  const [progress, setProgress] = React.useState(0)
  const [infoVideos, setInfoVideos] = React.useState([])
  //
  React.useEffect(() => {
    (async () => {
      const result = await fetchRenderInfos()
      setInfoVideos(result.data.map((item) => ({ video: item })))
    })()
  }, [])
  //
  React.useEffect(() => {
    intervalHandler = setInterval(async () => {
      if (!location.state?.assetId) return
      const result = await getProgress(cookies.jwt_token, location.state.assetId)
      if (!result) return
      if (result.renderProgress === 0 && result.videoUrl === null) {
        await setProgress(1)
        return
      }
      if (result.renderProgress === 100 && result.videoUrl === null) {
        await setProgress(99)
        return
      }
      if (result.renderProgress === 100 && result.videoUrl !== null) {
        clearInterval(intervalHandler)
        if (onComplete) onComplete(result)
        return
      }
      await setProgress(result.renderProgress)
    }, 2000)
    return () => clearInterval(intervalHandler)
  }, [cookies.jwt_token, location.state, onComplete])
  //
  return (
    <>
      <div className="info-videos-section flex flex-grow justify-center w-full">
        <VideoCarousel data={infoVideos} isPlayOnInit />
      </div>
      <div className="divider-tertiary w-full" />
      <div className="loading-section w-full">
        <ProgressBar now={progress} />
        <div style={{ marginTop: 8 }}>
          <span>{`${progress}%`}</span>
        </div>
      </div>

      <div className="result-note w-full">
        <span>꼭 알아두세요!</span>
        <br />
        <span style={{ color: 'var(--text-secondary)' }}>
          • 페이지에서 나가셔도 렌더링은 진행합니다.
        </span>
      </div>
    </>
  )
}

// export const DoneContent = ({ data }) => {
//   const history = useHistory()
//   const location = useLocation()
//   const { appState, appDispatch } = React.useContext(AppContext)
//   const [cookies] = useCookies(['jwt_token'])

//   const onClick = async (addVoice) => {
//     const result = await patchCampaign(
//       cookies.jwt_token,
//       appState.adCampaign.id,
//       { assetId: location.state.assetId },
//       2,
//     )
//     if (!result) return
//     const asset = { ...data }
//     asset.id = location.state.assetId
//     asset._id = location.state.assetId
//     // 서버에서 전달되는 객체에 오타로 인한 임시 코드
//     asset.vidoeUrl = data.videoUrl
//     await appDispatch({
//       type: 'updateAdCampaign',
//       payload: { ...result, asset },
//     })
//     if (addVoice) {
//       history.push('/portal/media/voice', asset)
//     } else {
//       history.push('/portal/media/create/3')
//     }
//   }

//   return (
//     <>
//       <div className="content-section flex-grow  w-full">
//         <video
//           className=" w-full"
//           src={data.videoUrl}
//           controlsList="nodownload"
//           onContextMenu={(e) => e.preventDefault()}
//           controls
//         />
//       </div>
//       <div className="flex flex-col-reverse items-center lg:flex-row gap-4 py-12">
//         <button
//           className="hidden xl:block flex-1 max-w-[264px] w-full secondary large"
//           onClick={() => onClick(true)}
//         >
//           성우 추가하기
//         </button>
//         <button
//           className="flex-1 max-w-[264px] w-full primary large"
//           onClick={() => onClick(false)}
//         >
//           소재 적용하기
//         </button>
//       </div>
//     </>
//   )
// }

export default ResultPage
