import React from 'react'
import { Container } from 'react-bootstrap'
import BasicLayout from './basic'
//
const SuccessStoriesLayout = ({ children }) => (
  <BasicLayout>
    <div className="page-headline">
      <Container>
        <h1>성공스토리</h1>
      </Container>
    </div>
    <div className="community page-content">{children}</div>
  </BasicLayout>
)

export default SuccessStoriesLayout
