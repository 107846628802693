import _ from 'lodash'

import { CONFIG } from './config'
import { transformAdCampaign } from './utils'
import { encrypt } from './encrypt'
//
export const getIdentityCheckHash = async () => {
  const REQUEST_URL = new URL('/user/signup/phoneVerify/encrypt', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('redirectUrl', new URL('/auth/identity-check', CONFIG.CLIENT_ENDPOINT))
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  //
  if (response.status !== 200) return false
  const text = await response.text()
  return text
}

export const checkUserIdAvailability = async (userId) => {
  const REQUEST_URL = new URL('/user/signup/userId', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const checkBusinessIdValidity = async (businessId, type) => {
  const REQUEST_URL = new URL('/user/signup/businessValid', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      bno: businessId,
      type,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const checkRecommnderIdValidity = async (recommendation_id) => {
  const REQUEST_URL = new URL('/user/signup/recommender', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      recommendId: recommendation_id,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const uploadBusinessDocument = async (file) => {
  const REQUEST_URL = new URL('/user/signup/uploadDocument', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  formData.append('document', file, file.name)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data.documentUrl
}

export const uploadSmallBusinessCert = async (file) => {
  const REQUEST_URL = new URL('/user/signup/uploadCert', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  formData.append('cert', file, file.name)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data.documentUrl
}

export const uploadCompanyIntro = async (file) => {
  const REQUEST_URL = new URL('/customer/partner/uploadDocument', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  formData.append('document', file, file.name)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data.documentUrl
}

export const requestPartner = async (data) => {
  const REQUEST_URL = new URL('/customer/partner', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      name: data.full_name,
      phone: data.phone_no,
      email: data.email,
      companyName: data.business_name,
      documentUrl: data.x_company_intro_url,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'SUCCESS') return result.data
  return false
}

export const submitSignUpForm = async (data) => {
  const REQUEST_URL = new URL('/user/signup', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      //
      userId: data.user_id || data.agency_id,
      userType: data.user_type,
      method: data.method,
      //
      companyName: data.business_name,
      companyNumber: data.business_id,
      documentUrl: data.x_business_document_url,
      certUrl: data.x_cert_url,
      //
      password: data.password,
      connInfo: data.identity_check?.connInfo,
      userName: data.full_name,
      userPhonenumber: data.phone_no,
      userEmail: data.email,
      recommender: data.recommendation_id,
      //
      aggre_email: data.email_notification,
      aggre_sms: data.sms_notification,
      marketing_privacy: data.marketing_privacy,
      marketing_subscribe: data.marketing_subscribe,
      //
      coupon: data.coupon,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'SUCCESS' || result.code === 'WAIT_SIGNUP') return result.data
  return false
}

export const submitRegisterForm = async (data) => {
  const REQUEST_URL = new URL('/user/register', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      //
      _id: data._id,
      companyName: data.business_name,
      companyNumber: data.business_id,
      documentUrl: data.x_business_document_url,
      certUrl: data.x_cert_url,
      //
      connInfo: data.identity_check?.connInfo,
      userName: data.full_name,
      userPhonenumber: data.phone_no,
      userEmail: data.email,
      recommender: data.recommendation_id,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'SUCCESS') return result.data
  return false
}

export const submitSignInForm = async (data) => {
  const REQUEST_URL = new URL('/user/login', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      userId: data.user_id,
      password: data.password,
      method: 'email',
    }),
  })
  const result = await response.json()
  return result
}

export const FindId = async (data) => {
  const REQUEST_URL = new URL('/user/findUserId', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      connInfo: data.connInfo,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const ValidateUserId = async (data) => {
  const REQUEST_URL = new URL('/user/validPasswd', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      userId: data.id,
      connInfo: data.identity_check.connInfo,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result
}

export const ChangeUserPwd = async (data) => {
  const REQUEST_URL = new URL('/user/recoverPasswd', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      userId: data.userId,
      password: data.password,
      connInfo: data.identity_check.connInfo,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const getUserInfo = async (token, data) => {
  const REQUEST_URL = new URL('/user/info', CONFIG.API_ENDPOINT)
  if (data?.type) REQUEST_URL.searchParams.set('type', data.type)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })

  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const updateUserInfo = async (token, data) => {
  const REQUEST_URL = new URL('/user/info', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const result = await response.json()
  return result
}

export const leaveService = async (token) => {
  const REQUEST_URL = new URL('/user/leave', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  const result = await response.json()
  return result
}

export const submitChangePwdForm = async (token, data) => {
  const REQUEST_URL = new URL('/user/changePasswd', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      currentPassword: data.current_password,
      password: data.password,
    }),
  })
  if (response.status !== 200) return { status: response.status }
  const result = await response.json()
  return result
}

export const getNotices = async () => {
  const REQUEST_URL = new URL('/customer/notice', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getNotice = async (id) => {
  const REQUEST_URL = new URL(`/customer/notice/${id}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getFAQs = async (search) => {
  const REQUEST_URL = new URL('/customer/faq', CONFIG.API_ENDPOINT)
  if (search) REQUEST_URL.searchParams.set('search', search)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const searchAddresses = async (text) => {
  const REQUEST_URL = new URL('/media/target', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('search', text)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data?.Items
}

export const searchNameAddresses = async (text) => {
  const REQUEST_URL = new URL('/media/target_name', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('search', text)
  //
  const response = await fetch(REQUEST_URL, { method: 'GET' })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data?.Items
}

export const getAddresses = async (city) => {
  const REQUEST_URL = new URL('/media/cities', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('cityId', city || '')
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data?.children
}

export const getBannerData = async () => {
  const REQUEST_URL = new URL('/info/banner', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getNoticeData = async () => {
  const REQUEST_URL = new URL('/info/notice', CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getCommunityPosts = async (boardId, page) => {
  const REQUEST_URL = new URL(`/community/${boardId}`, CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('limit', 16)
  REQUEST_URL.searchParams.set('page', page || 1)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getPost = async (boardId, postId) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}`, CONFIG.API_ENDPOINT)
  //
  let response = null
  response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const toggleLike = async (token, boardId, postId, state) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/like`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: state ? 'PATCH' : 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const calcRegions = async (regions) => {
  const REQUEST_URL = new URL('/media/count', CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      regions: regions.map((item) => item.key),
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result
}

export const getAssetsCategories = async () => {
  const REQUEST_URL = new URL('/media/design/category', CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const calcBudget = async (token, regions, count, period, periodMonth, amount, multiplier, userType = '0') => {
  const REQUEST_URL = new URL('/media/calculate', CONFIG.API_ENDPOINT)
  let response = null
  const headers = {
    'content-type': 'application/json',
  }
  if (token) {
    headers.Authorization = `Bearer ${token.accessToken}`
  }
  if (!amount) {
    response = await fetch(REQUEST_URL, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        regions: regions.map((item) => ({
          id: item.key,
          name: item.value,
          depth: item.depth,
        })),
        period,
        periodMonth,
        count,
        multiplier,
        userType,
      }),
    })
  } else {
    response = await fetch(REQUEST_URL, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        regions: regions.map((item) => ({
          id: item.key,
          name: item.value,
          depth: item.depth,
        })),
        period,
        periodMonth,
        count,
        amount,
        multiplier,
        userType,
      }),
    })
  }
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const getDesigns = async (type, ids, datas) => {
  const REQUEST_URL = new URL(`/media/design/${type}`, CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('limit', CONFIG.TEMPLATE_COUNT_LIMIT)
  if (datas) {
    Object.keys(datas).forEach((key) => {
      REQUEST_URL.searchParams.set(key, datas[key])
    })
  }
  if (ids && ids.length > 0) REQUEST_URL.searchParams.set('id', ids.join(','))
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const postAsset = async (token, id) => {
  const REQUEST_URL = new URL('/media/asset', CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      design: id,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const copyTrialAsset = async (designId, subtemplateId, assetId, videoDuration) => {
  const REQUEST_URL = new URL('/media/asset/trial', CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      design: designId,
      subtemplateId,
      assetId,
      videoDuration,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const updateAsset = async (token, id, data) => {
  const REQUEST_URL = new URL(`/media/asset/${id}`, CONFIG.API_ENDPOINT)
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result.data
}

export const patchAssetDone = async (assetId, videoUrl) => {
  const REQUEST_URL = new URL('/media/asset-callback', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ subtemplate: assetId, videoUrl }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const getStories = async () => {
  const REQUEST_URL = new URL('/success', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getStory = async (storyId) => {
  const REQUEST_URL = new URL(`/success/${storyId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const postCommunityPost = async (userId, boardId, data) => {
  const REQUEST_URL = new URL(`/community/${boardId}`, CONFIG.API_ENDPOINT)
  //
  const body = {
    store: data.community_post_store,
    name: data.community_post_name,
    title: data.community_post_title,
    contents: data.community_post_html,
    videoUrl: data.community_post_asset,
    visible: !data.community_post_locked,
  }
  if (!userId) {
    body.phone = data.community_post_phone
    body.email = data.community_post_email
    body.password = data.community_post_password
  } else {
    body.userId = userId
  }
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
export const editCommunityPost = async (userId, boardId, data, postId) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}`, CONFIG.API_ENDPOINT)
  const body = {
    store: data.community_post_store,
    name: data.community_post_name,
    title: data.community_post_title,
    contents: data.community_post_html,
    videoUrl: data.community_post_asset,
    visible: !data.community_post_locked,
  }
  if (!userId) {
    body.phone = data.community_post_phone
    body.email = data.community_post_email
    body.password = data.community_post_password
  } else {
    body.userId = userId
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
export const deleteCommunityPost = async (userId, boardId, postId) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    body: JSON.stringify({ userId }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.message
}
//
export const checkPostPassword = async (boardId, postId, password) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/password`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ password }),
  })
  const result = await response.json()
  return result
}
//
export const getAsset = async (token, assetId) => {
  const REQUEST_URL = new URL(`/media/asset/${assetId}`, CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('onlyAsset', 'true')
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
//
export const getProgress = async (token, assetId) => {
  const REQUEST_URL = new URL(`/media/asset/${assetId}`, CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('progress', 'true')
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const postCommunityComment = async (token, boardId, postId, text, recaptchaToken, writter) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/comment`, CONFIG.API_ENDPOINT)
  const headers = { 'content-type': 'application/json' }
  const body = {
    contents: text,
    token: recaptchaToken,
  }
  if (token?.accessToken) {
    headers.Authorization = `Bearer ${token.accessToken}`
  } else {
    body.userName = writter
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const editCommunityComment = async (token, boardId, postId, commentId, text) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/${commentId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      contents: text,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const postCommunitySubComment = async (token, boardId, postId, text, commentId, recaptchaToken, writter) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/${commentId}`, CONFIG.API_ENDPOINT)
  const headers = { 'content-type': 'application/json' }
  const body = {
    contents: text,
    token: recaptchaToken,
  }
  if (token?.accessToken) {
    headers.Authorization = `Bearer ${token.accessToken}`
  } else {
    body.userName = writter
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
export const editCommunitySubComment = async (token, boardId, postId, commentId, text) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/${commentId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      contents: text,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}
export const deleteCommunityComment = async (token, boardId, postId, commentId) => {
  const REQUEST_URL = new URL(`/community/${boardId}/${postId}/${commentId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.message
}

export const getAssets = async (token, page, order, filter, search) => {
  const REQUEST_URL = new URL('/media/asset', CONFIG.API_ENDPOINT)
  if (page) REQUEST_URL.searchParams.set('page', page)
  if (order) REQUEST_URL.searchParams.set('order', order)
  if (filter) REQUEST_URL.searchParams.set('filter', filter)
  if (search) REQUEST_URL.searchParams.set('search', search)
  REQUEST_URL.searchParams.set('limit', 12)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const deleteAssets = async (token, assetId) => {
  const REQUEST_URL = new URL(`/media/asset/${assetId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({}),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.message
}

export const getCommunityCategories = async (boardId) => {
  const REQUEST_URL = new URL(`/community/${boardId}/category`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const createCampaign = async (token, companyId, campaign, data) => {
  const REQUEST_URL = new URL('/media/campaign', CONFIG.API_ENDPOINT)
  let body = {}
  if (companyId) body.userId = companyId
  if (campaign) {
    body = {
      ...body,
      campaign: transformAdCampaign(campaign, 1),
    }
  }
  if (data) {
    body = {
      ...body,
      campaign: {
        ...body.campaign,
        ...data,
      },
    }
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'ALREADY_EXISTS_CAMPAIGN') return { code: 'ALREADY_EXISTS_CAMPAIGN', data: result.data }
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const copyCampaign = async (token, campaignId) => {
  const REQUEST_URL = new URL(`/media/campaign/${campaignId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({}),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const deleteCampaign = async (token, campaignId) => {
  const REQUEST_URL = new URL(`/media/campaign/${campaignId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({}),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const patchCampaign = async (token, campaignId, data, step) => {
  const REQUEST_URL = new URL(`/media/campaign/${campaignId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(transformAdCampaign(data, step)),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const requestTheDreamInspection = async (token, campaignId) => {
  const REQUEST_URL = new URL(`/invoice/thedream/inspection/${campaignId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const requestInspection = async (token, campaignId) => {
  const REQUEST_URL = new URL(`/invoice/inspection/${campaignId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getInvoiceToken = async (token, data) => {
  const REQUEST_URL = new URL('/invoice/ready', CONFIG.API_ENDPOINT)
  //
  const obj = {
    method: data.ad_campaign_payment_method,
    campaignId: data.isRequested ? 'agency' : data.ad_campaign_id,
    price: encrypt(`${data.ad_campaign_cost}`),
  }

  // TODO: 추후 실제 사은품 데이터를 조회해와서 적절한 _id 값을 전달해야합니다. 현재는 임시로 고정값을 전달하고 있습니다.
  if (data.ad_campaign_gift === 'true') {
    obj.gift = '64eed06db26691909a0cdbb6'
  }
  if (data.ad_campaign_discount_method === 'point') {
    obj.point = encrypt(`${data.ad_campaign_point_val}`)
  }
  //
  if (data.encryptedUserAgent !== '') obj.encryptedUserAgent = data.encryptedUserAgent
  //
  if (data.ad_campaign_coupon && data.ad_campaign_coupon !== 'none') obj.couponId = data.ad_campaign_coupon
  //
  if (data.isMobile) obj.isMobile = true
  if (data.resultParams) obj.resultParams = data.resultParams

  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getCampaigns = async (token, companyId, companyRank, myCompanyId, order, page, filter) => {
  const REQUEST_URL = new URL('/media/campaign', CONFIG.API_ENDPOINT)
  if (companyId && companyId !== 'all') REQUEST_URL.searchParams.set('companyId', companyId)
  if (companyRank) REQUEST_URL.searchParams.set('companyRank', companyRank)
  if (myCompanyId) REQUEST_URL.searchParams.set('myCompanyId', myCompanyId)
  if (order) REQUEST_URL.searchParams.set('order', order)
  if (filter) REQUEST_URL.searchParams.set('filter', filter)
  if (page) REQUEST_URL.searchParams.set('page', page)
  REQUEST_URL.searchParams.set('limit', 10)
  //
  const response = await fetch(REQUEST_URL, {
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getPaymentInfo = async () => {
  const REQUEST_URL = new URL('/info/payment', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const postSKPayResult = async (token, data) => {
  const REQUEST_URL = new URL('/invoice/result', CONFIG.API_ENDPOINT)
  //
  const obj = {
    paymentId: data.paymentId,
    invoiceId: data.ad_campaign_invoice_id,
    campaignId: data.ad_campaign_id,
    price: data.ad_campaign_cost,
  }
  if (data.ad_campaign_coupon) obj.couponId = data.ad_campaign_coupon
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result
}

// export const postSKPayResult = async (token, data) => {
//   const REQUEST_URL = new URL('/invoice/result', CONFIG.API_ENDPOINT)
//   //
//   const obj = {
//     paymentId: data.paymentId,
//     invoiceId: data.ad_campaign_invoice_id,
//     campaignId: data.ad_campaign_id,
//     price: data.ad_campaign_cost,
//   }
//   if (data.ad_campaign_coupon) obj.couponId = data.ad_campaign_coupon
//   //
//   const response = await fetch(REQUEST_URL, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token.accessToken}`,
//       'content-type': 'application/json',
//     },
//     body: JSON.stringify(obj),
//   })
//   if (response.status !== 200) return false
//   const result = await response.json()
//   return result
// }

export const getCoupons = async (token) => {
  const REQUEST_URL = new URL('/invoice/coupons', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const postCoupon = async (token, couponToken) => {
  const REQUEST_URL = new URL('/invoice/coupons', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ couponToken }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  return result
}

export const getPoints = async (token) => {
  const REQUEST_URL = new URL('/invoice/point', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getInvoiceInfo = async (token, invoiceId) => {
  const REQUEST_URL = new URL(`/invoice/info/${invoiceId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getInvoices = async (token) => {
  const REQUEST_URL = new URL('/invoice', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const deleteInvoice = async (token, invoiceId) => {
  const REQUEST_URL = new URL(`/invoice/inicis/cancel/${invoiceId}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getLandingPageInfo = async () => {
  const REQUEST_URL = new URL('/info/landing', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data?.data
}

export const uploadAsset = async (token, file) => {
  const REQUEST_URL = new URL('/media/upload', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  formData.append('video', file, file.name)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result
}

export const fetchStatistics = async (campaignId, start, end) => {
  const REQUEST_URL = new URL(`/media/statistics/${campaignId}`, CONFIG.API_ENDPOINT)
  if (start) REQUEST_URL.searchParams.set('start', start)
  if (end) REQUEST_URL.searchParams.set('end', end)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result
}

export const fetchAgencyInfo = async (id) => {
  const REQUEST_URL = new URL(`/info/agency/${id}`, CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result
}

export const fetchRenderInfos = async () => {
  const REQUEST_URL = new URL('/info/render', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const fetchContracts = async (token, companyRank, myCompanyId) => {
  const REQUEST_URL = new URL('/contract', CONFIG.API_ENDPOINT)
  if (companyRank) REQUEST_URL.searchParams.set('companyRank', companyRank)
  if (myCompanyId) REQUEST_URL.searchParams.set('myCompanyId', myCompanyId)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  const result = await response.json()
  return result.data
}

export const requestAuth = async (token, userId) => {
  const REQUEST_URL = new URL('/contract', CONFIG.API_ENDPOINT)
  const body = {
    user: {
      _id: userId,
    },
  }
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  const result = await response.json()
  return result
}

export const updateContractStatus = async (token, data) => {
  const REQUEST_URL = new URL('/contract', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const result = await response.json()
  return result
}

export const fetchContractUserInfo = async (token, id) => {
  const REQUEST_URL = new URL('/contract/user', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('id', id)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  const result = await response.json()
  return result
}

export const testRecaptcha = async (token) => {
  const REQUEST_URL = new URL('/community/recaptcha', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ token }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const requestConsultation = async (data) => {
  const REQUEST_URL = new URL('/customer/consult', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getGalleryCategories = async () => {
  const REQUEST_URL = new URL('/info/gallery-category', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const createPromotion = async (token, body) => {
  const REQUEST_URL = new URL('/gallery', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token?.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result
}

export const updatePromotion = async (token, body) => {
  const REQUEST_URL = new URL('/gallery', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token?.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result
}

export const getRegionData = async (searchKeyword) => {
  const REQUEST_URL = new URL('tmap/pois', 'https://apis.openapi.sk.com')
  REQUEST_URL.searchParams.set('appKey', CONFIG.TMAP_APP_KEY)
  REQUEST_URL.searchParams.set('version', 1)
  REQUEST_URL.searchParams.set('format', 'json')
  REQUEST_URL.searchParams.set('count', 100)
  REQUEST_URL.searchParams.set('searchKeyword', searchKeyword)
  //
  const response = await fetch(REQUEST_URL)
  if (!response) return
  if (response.status === 204) return
  const result = await response.json()
  if (!result || result.error) return
  //
  // console.log(result.searchPoiInfo)
  const {
    pois: { poi },
  } = result.searchPoiInfo
  if (!poi || poi.length === 0) {
    return
  }
  const addrDatas = poi.map((item) => {
    const {
      newAddressList,
      frontLat,
      frontLon,
      upperAddrName,
      middleAddrName,
      lowerAddrName,
      roadName,
      firstBuildNo,
      secondBuildNo,
      zipCode,
      telNo,
    } = item
    if (newAddressList?.newAddress) {
      const [addrInfo] = newAddressList.newAddress
      return {
        ...addrInfo,
        zipCode,
        telNo,
        middleAddrName,
        lowerAddrName,
      }
    }
    const fullAddressRoadArr = []
    if (upperAddrName) fullAddressRoadArr.push(upperAddrName)
    else if (middleAddrName) fullAddressRoadArr.push(middleAddrName)
    else if (roadName) fullAddressRoadArr.push(roadName)
    else if (firstBuildNo) fullAddressRoadArr.push(firstBuildNo)
    else if (secondBuildNo && secondBuildNo !== '0') fullAddressRoadArr.push(secondBuildNo)
    const fullAddressRoad = fullAddressRoadArr.join(' ')
    return {
      frontLat,
      frontLon,
      fullAddressRoad,
      zipCode,
      telNo,
      middleAddrName,
      lowerAddrName,
    }
  })
  return _.uniqBy(addrDatas, (item) => item.fullAddressRoad)
}

export const getAdminDong = async (fullAddress) => {
  const REQUEST_URL = new URL('tmap/geo/postcode', 'https://apis.openapi.sk.com')
  REQUEST_URL.searchParams.set('appKey', CONFIG.TMAP_APP_KEY)
  REQUEST_URL.searchParams.set('version', 1)
  REQUEST_URL.searchParams.set('format', 'json')
  REQUEST_URL.searchParams.set('addr', fullAddress)
  REQUEST_URL.searchParams.set('addressFlag', 'F00')
  //
  const response = await fetch(REQUEST_URL)
  if (!response) return
  if (response.status === 204) return
  const result = await response.json()
  if (!result || result.error) return
  //
  const [data] = result.coordinateInfo.coordinate
  if (data) {
    return data.adminDong
  }
  return null
}

export const getRegionByFullAddr = async (fullAddress) => {
  const REQUEST_URL = new URL('tmap/geo/fullAddrGeo', 'https://apis.openapi.sk.com')
  REQUEST_URL.searchParams.set('appKey', CONFIG.TMAP_APP_KEY)
  REQUEST_URL.searchParams.set('version', 1)
  REQUEST_URL.searchParams.set('format', 'json')
  REQUEST_URL.searchParams.set('fullAddr', fullAddress)
  REQUEST_URL.searchParams.set('addressFlag', 'F00')
  //
  const response = await fetch(REQUEST_URL)
  if (!response) return
  if (response.status === 204) return
  const result = await response.json()
  if (!result || result.error) return
  //
  const [data] = result.coordinateInfo.coordinate
  if (data) {
    const {
      lat, lon, newLat, newLon,
    } = data
    return { lat: lat || newLat, lon: lon || newLon }
  }
  return null
}

export const uploadStoreImage = async (files) => {
  const REQUEST_URL = new URL('/gallery/image', CONFIG.API_ENDPOINT)
  //
  const formData = new FormData()
  files.forEach((file) => formData.append(
    'images',
    file,
    `${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}.${file.name
      .split('.')
      .pop()
      .toLowerCase()}`,
  ))
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    body: formData,
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data.imageUrls
}

export const getBestGallery = async () => {
  const REQUEST_URL = new URL('/gallery/best', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getGalleries = async (companySector, regions, page) => {
  const REQUEST_URL = new URL('/gallery', CONFIG.API_ENDPOINT)
  if (companySector) REQUEST_URL.searchParams.set('companySector', companySector)
  if (regions) REQUEST_URL.searchParams.set('regions', regions)
  REQUEST_URL.searchParams.set('count', CONFIG.GALLERIES_COUNT)
  REQUEST_URL.searchParams.set('page', page || 1)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getGalleryDetail = async (galleryId, masking = true) => {
  const REQUEST_URL = new URL('/gallery/detail', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('galleryId', galleryId)
  REQUEST_URL.searchParams.set('masking', masking)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const searchGallery = async (search, page) => {
  const REQUEST_URL = new URL('/gallery/search', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('q', search)
  REQUEST_URL.searchParams.set('page', page)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getPromotions = async (token) => {
  const REQUEST_URL = new URL('/gallery/manage', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const patchGalleryVisible = async (token, galleryId, visible) => {
  const REQUEST_URL = new URL('/gallery/visible', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ galleryId, visible }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const preparePreview = async (token, data) => {
  const REQUEST_URL = new URL('/media/voice/preview', CONFIG.STAGE_API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const saveVoices = async (token, data) => {
  const REQUEST_URL = new URL('/media/voice', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return true
}

export const getSubtemplate = async (assetId) => {
  const REQUEST_URL = new URL(`/subtemplate/${assetId}`, CONFIG.VPLATE_API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'SUBTEMPLATE_NOT_FOUND') return null
  if (result.code !== 'SUCCESS') return false
  return result.result.design
}

export const getEvents = async () => {
  const REQUEST_URL = new URL('/info/event', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getProducts = async () => {
  const REQUEST_URL = new URL('/media/product', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const checkIsFirst = async (data) => {
  const REQUEST_URL = new URL('/media/first', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getRegionMultipliers = async () => {
  const REQUEST_URL = new URL('/info/regionMultiplier', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const getReferrers = async () => {
  const REQUEST_URL = new URL('/info/referrer', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code !== 'SUCCESS') return false
  return result.data
}

export const requestRecommendData = async (token, data) => {
  const REQUEST_URL = new URL('/recommend', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  const result = await response.json()
  return result.data
}

export const getBudgetData = async (token, category) => {
  const REQUEST_URL = new URL('/recommend/budget', CONFIG.API_ENDPOINT)
  REQUEST_URL.searchParams.set('category', category)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  })
  const result = await response.json()
  return result.data
}

export const submitExpoForm = async (data) => {
  const REQUEST_URL = new URL('/customer/expoUser', CONFIG.API_ENDPOINT)
  //
  const response = await fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      name: data.name,
      tel: data.tel,
    }),
  })
  if (response.status !== 200) return false
  const result = await response.json()
  if (result.code === 'SUCCESS') return result.data
  return false
}
