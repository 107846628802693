import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useForm, FormProvider, useWatch } from 'react-hook-form'

import Formtextbox from '../../components/formTextbox'
import Editor from '../../components/editor'
import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import { postCommunityPost, editCommunityPost } from '../../services/api'
import SelectAssetModal from './components/selectAssetModal'
import InputValidation from '../../components/inputValidation'
import { FormCheckbox } from '../../components/checkbox'
//
const EditorPage = () => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const { appState } = React.useContext(AppContext)
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [isModalShown, setIsModalShown] = React.useState(false)
  //
  const { getValues, setValue, ...methods } = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      community_post_store: '',
      community_post_name: '',
      community_post_html: '',
      community_post_title: '',
      community_post_asset: null,
      community_post_locked: false,
    },
  })
  //
  methods.register('community_post_store', { required: true })
  methods.register('community_post_name', { required: true })
  methods.register('community_post_title', { required: true })
  methods.register('community_post_html', { required: true })
  methods.register('community_post_asset', {
    validate: {
      asset_required: async (val) => {
        if (params.pathname === '/community' && val === null) return false
        return true
      },
    },
  })
  React.useEffect(() => {
    if (!appState.userInfo?._id) {
      methods.register('community_post_phone', { required: true })
      methods.register('community_post_email', { required: true })
      methods.register('community_post_password', { required: true })
    } else {
      methods.unregister('community_post_phone')
      methods.unregister('community_post_email')
      methods.unregister('community_post_password')
    }
  }, [appState.userInfo?._id])
  //
  const title_value = useWatch({
    name: 'community_post_title',
    control: methods.control,
  })
  const html_value = useWatch({
    name: 'community_post_html',
    control: methods.control,
  })
  const asset_value = useWatch({
    name: 'community_post_asset',
    control: methods.control,
  })
  //
  const onCancelClick = async (e) => {
    e.preventDefault()
    history.goBack()
  }
  //
  const onSubmit = async (data) => {
    let result = null
    const { boardId } = params
    if (location.state) {
      result = await editCommunityPost(
        appState.userInfo?._id,
        boardId,
        data,
        location.state._id,
      )
    } else {
      result = await postCommunityPost(appState.userInfo?._id, boardId, data)
    }
    if (!result) return
    history.replace(`/board/${boardId}`)
  }
  //
  const onAssetSelect = async (e, data) => {
    await setIsModalShown(false)
    await setValue('community_post_asset', data.vidoeUrl)
  }
  //
  React.useEffect(() => {
    (async () => {
      if (title_value || html_value) await setIsDisabled(false)
      else await setIsDisabled(true)
    })()
  }, [title_value, html_value, setIsDisabled])
  React.useEffect(() => {
    if (location.state) {
      setValue('community_post_store', location.state.store)
      setValue('community_post_name', location.state.name)
      setValue('community_post_title', location.state.title)
      setValue('community_post_asset', location.state.videoUrl, { shouldValidate: true })
      setValue('community_post_phone', location.state.phone)
      setValue('community_post_email', location.state.email)
      setValue('community_post_locked', !location.state.visible)
    }
  }, [location.state, setValue])
  //
  const onReady = async (editor) => {
    if (location.state?.contents) {
      await editor.setData(location.state.contents)
    }
  }
  const onChange = async (event, editor) => {
    const data = await editor.getData()
    await setValue('community_post_html', data, {
      shouldValidate: true,
    })
  }
  //
  const onOpenModalClick = async (e) => {
    e.preventDefault()
    await setIsModalShown(true)
  }
  //
  const getTitle = React.useCallback(() => {
    if (location?.pathname.includes('event0816')) {
      return '이벤트 사연 작성하기'
    }
    return '게시물 작성하기'
  }, [location?.pathname])
  //
  return (
    <BasicLayout className="community-editor">
      <Container>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col create-post-paper">
              <div className="title-section">
                <h2 className="mb-6">{getTitle()}</h2>
                <div className="flex flex-col gap-8 mb-8 md:flex-row md:items-center">
                  <div className="flex flex-col gap-1">
                    <h3 className="text-base mb-2">우리 가게 이름</h3>
                    <Formtextbox
                      className="medium border"
                      name="community_post_store"
                      autoComplete="off"
                      placeholder="매장명을 입력해주세요"
                      validators={{
                        required: true,
                      }}
                      validations={['required']}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h3 className="text-base mb-2">사장님 이름(또는 별칭)</h3>
                    <Formtextbox
                      className="medium border"
                      name="community_post_name"
                      autoComplete="off"
                      placeholder="닉네임을 입력해주세요"
                      validators={{
                        required: true,
                      }}
                      validations={['required']}
                    />
                  </div>
                </div>
                {!appState.userInfo?._id && (
                  <div className="flex flex-col gap-8 mb-8 lg:flex-row lg:items-center">
                    <div className="flex flex-col gap-8 md:flex-row md:items-center">
                      <div className="flex flex-col gap-1">
                        <h3 className="text-base mb-2">전화번호</h3>
                        <Formtextbox
                          className="medium border"
                          name="community_post_phone"
                          autoComplete="off"
                          placeholder="전화번호를 입력해주세요"
                          validators={{
                            required: true,
                          }}
                          validations={['required']}
                        />
                      </div>
                      <div className="flex flex-col">
                        <h3 className="text-base mb-2">이메일</h3>
                        <Formtextbox
                          className="medium border"
                          name="community_post_email"
                          autoComplete="off"
                          placeholder="이메일을 입력해주세요"
                          validators={{
                            required: true,
                          }}
                          validations={['required']}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <h3 className="text-base mb-2">비밀번호</h3>
                      <Formtextbox
                        className="medium border"
                        name="community_post_password"
                        autoComplete="off"
                        placeholder="비밀번호를 입력해주세요"
                        validators={{
                          required: true,
                        }}
                        validations={['required']}
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-center">
                  <h3 className="text-base mb-3 mr-4">우리 가게 홍보가 필요한 사유</h3>
                  <FormCheckbox
                    name="community_post_locked"
                  >
                    비공개
                  </FormCheckbox>
                </div>
                <Formtextbox
                  name="community_post_title"
                  value={title_value}
                  className="title larger border"
                  placeholder="제목"
                  validators={{ required: true }}
                />
              </div>
              <div className="editor-section">
                <Editor
                  data={html_value}
                  onChange={onChange}
                  onReady={onReady}
                />
              </div>
              {appState.userInfo?._id && (
                <div className="asset-section">
                  <div className="flex flex-row items-center">
                    <button
                      className="large dark whitespace-nowrap"
                      onClick={async (e) => onOpenModalClick(e)}
                    >
                      영상 등록
                    </button>
                    <small>{asset_value || '현재 송출 중인 광고 영상이 있으시면 올려 주세요 (선택)'}</small>
                  </div>
                </div>
              )}
              <div className="action-section flex flex-row justify-center gap-4">
                <button
                  type="cancel"
                  onClick={onCancelClick}
                  className="secondary large whitespace-nowrap"
                >
                  취소
                </button>
                <button
                  type="submit"
                  disabled={isDisabled}
                  className="primary large whitespace-nowrap"
                >
                  {location.state?._id ? '수정' : '등록'}
                </button>
              </div>
              <div className="validations-section">
                <InputValidation
                  names={[
                    'community_post_store',
                    'community_post_name',
                    'community_post_title',
                    'community_post_html',
                    'community_post_asset',
                    'community_post_phone',
                    'community_post_email',
                    'community_post_password',
                  ]}
                  validations={['asset_required']}
                />
              </div>
            </div>
          </form>
          {appState.userInfo?._id && (
            <SelectAssetModal
              onAssetSelect={onAssetSelect}
              isShown={isModalShown}
              onClose={async () => setIsModalShown(false)}
            />
          )}
        </FormProvider>
      </Container>
    </BasicLayout>
  )
}

export default EditorPage
