import React from 'react'

const ToggleMenu = ({
  menu = [], children, onClick, isSelected, onClickMenu, ...props
}) => {
  //
  const buttonClasses = ['toggle-menu']
  if (props.className) buttonClasses.push(props.className)
  //
  //
  const onActionClick = async (e, item) => {
    const obj = { ...item }
    delete obj.disabledIcon
    delete obj.enabledIcon
    onClickMenu()
    if (onClick) onClick(e, obj)
  }
  //
  return (
    <div className="menu-wrapper">
      <div className={buttonClasses.join(' ')}>
        <button className="action light" {...props} onClick={onClickMenu}>
          {children}
        </button>
        <div className="menu-holder" style={{ display: isSelected ? 'block' : 'none' }}>
          {menu.map((item, index) => (
            <button
              key={index}
              className="item w-full light right-icon"
              onClick={(e) => onActionClick(e, item)}
              disabled={item.disabled}
            >
              {item.label}
              <img src={item.disabled ? item.disabledIcon : item.enabledIcon} alt="icon" />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export const menuActions = {
  MODIFY: 'MODIFY',
  CHANGE_NAME: 'CHANGE_NAME',
  DOWNLOAD: 'DOWNLOAD',
  DETAIL: 'DETAIL',
  COPY: 'COPY',
  DELETE: 'DELETE',
}

export default ToggleMenu
