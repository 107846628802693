import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Container } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { datadogRum } from '@datadog/browser-rum'

import moment from 'moment'
import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import targetRight from '../../assets/icons/icon-outlined-directional-right-large.svg'
import FormTextbox from '../../components/formTextbox'
import InputValidation from '../../components/inputValidation'
import { submitSignInForm, getUserInfo, createCampaign } from '../../services/api'
import SocialAuth from './components/socialAuth'
//
const SignIn = () => {
  const history = useHistory()
  const [, setCookie] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const methods = useForm({ criteriaMode: 'all', mode: 'onSubmit' })
  const { appState, appDispatch } = React.useContext(AppContext)
  //
  const onSubmit = async (data) => {
    const signinResult = await submitSignInForm(data)
    if (signinResult.code === 'USER_EXPIRED') {
      enqueueSnackbar('탈퇴하신 회원 계정입니다', { variant: 'error' })
      return
    }
    if (signinResult.code === 'OVER_TRIALS') {
      enqueueSnackbar('비밀번호를 5회 잘못 입력하셨습니다. 1시간 뒤에 다시 시도해주세요.', { variant: 'error' })
      return
    }
    if (signinResult.message) {
      enqueueSnackbar(signinResult.message, { variant: 'error' })
      return
    }
    const token = signinResult.data
    if (!token) {
      enqueueSnackbar('로그인에 실패했습니다', { variant: 'error' })
      return
    }
    await setCookie('jwt_token', token, {
      path: '/',
      maxAge: 86400 /* 1 day = (60 * 60 * 24) */,
    })
    const result = await getUserInfo(token)
    await appDispatch({ type: 'updateUserInfo', payload: result })
    localStorage.setItem('gallery-detail-count', JSON.stringify({ date: moment().format('YYYY-MM-DD'), count: 0 }))
    datadogRum.setUser({
      id: result.id,
      name: result.userName,
      type: result.userType,
    })
    if (appState.tempEstimateResult) {
      const createResult = await createCampaign(token, null, appState.tempEstimateResult)
      if (!createResult) {
        if (query.get('previous')) {
          history.push(decodeURIComponent(query.get('previous')))
        } else {
          history.push('/portal/media')
        }
        return
      }
      await appDispatch({ type: 'updateAdCampaign', payload: createResult })
      await appDispatch({ type: 'saveEstimateResultTemp', payload: null })
      history.push('/portal/media/create/1')
      return
    }
    if (query.get('previous')) {
      history.push(decodeURIComponent(query.get('previous')))
    } else {
      history.push('/portal/media')
    }
  }
  //
  return (
    <BasicLayout>
      <Container>
        <div className="auth-paper">
          <div className="flex flex-col lg:flex-row justify-items-stretch">
            <FormProvider {...methods}>
              <form className="w-full" onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="header">
                  <h1>로그인</h1>
                  <p>
                    <span>Btv 우리동네광고에 오신걸 환영합니다</span>
                    <br />
                    <span>로그인 후 다양한 서비스를 경험해보세요!</span>
                  </p>
                </div>
                <FormTextbox
                  className="larger"
                  name="user_id"
                  placeholder="아이디를 입력해주세요"
                  validators={{ required: true }}
                />
                <InputValidation names={['user_id']} validations={['required']} />
                <FormTextbox
                  className="larger"
                  name="password"
                  type="password"
                  autoComplete="off"
                  placeholder="비밀번호를 입력해주세요"
                  validators={{ required: true }}
                />
                <InputValidation names={['password']} validations={['required']} />
                <button className="w-full primary large" type="submit">
                  로그인 하기
                </button>
                <div className="recover-targets">
                  <Link to="/recover-id">아이디 찾기</Link>
                  <Link to="/recover-password">비밀번호 찾기</Link>
                </div>
              </form>
            </FormProvider>
            <div className="divider-vertical" />
            <div className="w-full">
              <div className="header">
                <h2 style={{ marginTop: '25px' }}>소셜 로그인</h2>
                <p>
                  <span>간편한 방법으로 로그인 할 수 있습니다</span>
                </p>
              </div>
              <SocialAuth />
            </div>
          </div>
          <div style={{ marginTop: '56px' }} className="w-full auth-note flex flex-col md:flex-row justify-between">
            <p>Btv 우리동네광고를 처음 이용하거나 아직 회원이 아니신가요?</p>
            <Link
              style={{ color: 'var(--text-secondary)' }}
              className="right-icon"
              to={{ pathname: '/sign-up', search }}
            >
              회원가입 하기
              <img src={targetRight} alt="target-right" />
            </Link>
          </div>
        </div>
      </Container>
    </BasicLayout>
  )
}

export default SignIn
