import React from 'react'

import { TwoButtonModal } from '../../../components/modals'
import { checkPostPassword } from '../../../services/api'
import useTextBox from '../../../hooks/useTextBox'

const usePasswordModal = () => {
  const [isModalShown, setIsModalShown] = React.useState(false)
  const [post, setPost] = React.useState(null)
  const { textValue, setTextValue, handleChange } = useTextBox('')
  const cbFunc = React.useRef(null)

  const open = React.useCallback(
    (_post, callback) => {
      setTextValue('')
      setPost(_post)
      cbFunc.current = callback
      setIsModalShown(true)
    },
    [],
  )

  const close = React.useCallback(
    () => {
      setIsModalShown(false)
    },
    [],
  )

  const onClickModalDone = React.useCallback(
    async () => {
      const result = await checkPostPassword(post.boardId, post._id, textValue)
      setIsModalShown(false)
      if (cbFunc.current) cbFunc.current(post._id, result.code)
    },
    [post, textValue],
  )

  const renderPasswordModal = () => isModalShown && (
    <TwoButtonModal
      title="비밀번호 입력"
      isShown={isModalShown}
      onClose={close}
      primaryText="완료"
      secondaryText="닫기"
      onClickPrimary={onClickModalDone}
      onClickSecondary={close}
    >
      <div className="textbox-wrapper w-full">
        <input
          className="w-full"
          type="text"
          value={textValue}
          onChange={handleChange}
        />
      </div>
    </TwoButtonModal>
  )

  return { open, renderPasswordModal }
}

export default usePasswordModal
