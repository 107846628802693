import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SuccessStoriesLayout from '../../layouts/successStoriesLayout'
import { getStories } from '../../services/api'
import emptyMedia from '../../assets/icons/empty-media.svg'

const SuccessStories = () => {
  const [state, setState] = React.useState([])
  const history = useHistory()
  //
  const onClick = async (e, item) => {
    history.push(`success-stories/${item._id}`)
  }
  //
  React.useEffect(() => {
    (async () => {
      const result = await getStories()
      if (!result) return
      await setState(result)
    })()
  }, [])
  //
  return (
    <SuccessStoriesLayout>
      <Container>
        <div className="grid grid-cols-2 gap-16">
          {state.map((item, index) => (
            <button
              key={index}
              className="story-snippet"
              onClick={async (e) => onClick(e, item)}
            >
              <img
                className="w-full"
                src={item.description.img || emptyMedia}
                alt="media"
              />
              <p>{item.description.title}</p>
            </button>
          ))}
        </div>
      </Container>
    </SuccessStoriesLayout>
  )
}

export default SuccessStories
