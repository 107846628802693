import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
//
import { useCookies } from 'react-cookie'
import BasicLayout from '../../layouts/basic'
//
import TermsConditions from './components/terms&Conditions'
import SignUpHeader from './components/signUpHeader'
//
import AppContext from '../../services/context'
import { getUserInfo, submitSignUpForm } from '../../services/api'
//
const SignUpFormSocial = () => {
  const history = useHistory()
  const { appState, appDispatch } = React.useContext(AppContext)
  const { search } = useLocation()
  const [, setCookie] = useCookies(['jwt_token'])
  //
  const user_id = appState.signUpMode.state?.userId
  const method = appState.signUpMode.method?.toLowerCase()

  const methods = useForm({
    criteriaMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      user_id,
      user_type: 0,
      method,
    },
  })
  //
  const onSubmit = async (data) => {
    const form = data
    if (search.coupon) {
      form.coupon = search.coupon
    }
    const result = await submitSignUpForm(form)
    if (!result) return
    await setCookie('jwt_token', result, {
      path: '/',
      maxAge: 86400 /* 1 day = (60 * 60 * 24) */,
    })
    const userResult = await getUserInfo(result)
    await appDispatch({ type: 'updateUserInfo', payload: userResult })
    datadogRum.setUser({
      id: result.id,
      name: result.userName,
      type: result.userType,
    })
    history.push('/portal/media')
  }
  //
  return (
    <BasicLayout>
      <div className="grid xl:grid-cols-4">
        <Container className="auth-paper xl:col-start-2 xl:col-span-2">
          <div className="flex flex-col justify-items-stretch justify-center">
            <div className="header">
              <SignUpHeader isSocial />
            </div>
            <div className="divider-primary" />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TermsConditions />
                {/**/}
                <button className="w-full primary large" type="submit">
                  회원가입 하기
                </button>
              </form>
            </FormProvider>
            {/**/}
          </div>
        </Container>
      </div>
    </BasicLayout>
  )
}

export default SignUpFormSocial
