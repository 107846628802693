import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, useWatch, FormProvider } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import AppContext from '../../services/context'
import { CONFIG } from '../../services/config'
import { hideFab } from '../../services/fab'
import BasicLayout from '../../layouts/basic'
import MessageModal from '../../components/modals'
import { FormCheckbox } from '../../components/checkbox'
import { FormTextboxGroup } from '../../components/formTextbox'
import BannerCarousel from '../../components/BannerCarousal'
import useCalendar from '../../hooks/useCalendar'
import useTimeSelector from './hooks/useTimeSelector'
import { requestConsultation } from '../../services/api'
import { holidays } from '../../services/dateUtils'

import illust from '../../assets/imgs/consult-illust.png'
import illust2x from '../../assets/imgs/consult-illust@2x.png'
import illust3x from '../../assets/imgs/consult-illust@3x.png'
import targetRight from '../../assets/icons/icon-outlined-directional-right-medium.svg'
import { formatDate, transformEstimateResult, getStrTime } from '../../services/utils'
import Radio from '../../components/radio'

//
const Consult = () => {
  const { appState, appDispatch } = React.useContext(AppContext)
  const scrollFlag = React.useRef(null)
  const location = useLocation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { date, renderCalendar } = useCalendar()
  const { selectedTime, renderTimeSelector } = useTimeSelector({ date })
  const [doneModalInfo, setDoneModalInfo] = React.useState({
    title: '',
    isShown: false,
  })
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      agree_privacy: false,
      category: 'iptv',
    },
  })
  const phone_no = useWatch({
    name: 'phone_no',
    control: methods.control,
  })
  const agree_privacy = useWatch({
    name: 'agree_privacy',
    control: methods.control,
  })
  hideFab()

  React.useEffect(() => {
    if (appState.userInfo?.userPhonenumber) {
      methods.setValue('phone_no', appState.userInfo.userPhonenumber)
    }
  }, [])

  React.useEffect(() => {
    if (scrollFlag.current) {
      scrollFlag.current.scrollIntoView(false)
    }
  }, [scrollFlag])

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault()
  }

  const onClickBack = () => {
    history.goBack()
  }
  //
  const onSubmit = React.useCallback(
    async (formData) => {
      let content = ''
      const strTime = getStrTime(selectedTime, true)
      const consultTimeText = `- 상담시간: ${formatDate(date)} (${strTime})`
      const { tempEstimateResult } = appState
      if (tempEstimateResult) {
        content = transformEstimateResult(tempEstimateResult)
      }
      if (location.state?.asset) {
        const { pathname, assetName } = location.state.asset
        content += `[url]: https://stage.btvtownad.net${pathname}\n`
        content += `[소재]: ${assetName}\n`
      }
      content += consultTimeText
      const trimmedPhoneNum = formData.phone_no.replace(/-/gi, '')
      const data = {
        user: appState.userInfo?.id,
        name: formData.name,
        phone: trimmedPhoneNum,
        category: formData.category,
        date: moment(date).format('YYYY-MM-DD'),
        hour: selectedTime,
        content,
      }
      const result = await requestConsultation(data)
      if (!result) {
        enqueueSnackbar('잘못된 요청입니다', { variant: 'error' })
        return
      }
      if (tempEstimateResult) {
        appDispatch({ type: 'saveEstimateResultTemp', payload: null })
      }
      setDoneModalInfo({
        title: '상담 요청이 완료되었습니다',
        isShown: true,
      })
    },
    [appState.tempEstimateResult, date, selectedTime],
  )
  //
  return (
    <BasicLayout>
      <div className="page-headline md:!block p-4" style={{ padding: 0 }}>
        <Container>
          <h1 className="mlg:!text-[1.5rem]">전화상담 예약</h1>
          <div className="flex justify-between items-center">
            <img src={illust} srcSet={`${illust2x} 2x, ${illust3x} 3x`} alt="illust" />
          </div>
        </Container>
      </div>
      <div className="md:hidden">
        <BannerCarousel />
      </div>
      <div className="page-content estimate">
        <Container className="bg-bgColor lg:bg-white pb-0 md:pb-[56px]">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit, (e) => console.log(e))} onKeyDown={(e) => checkKeyDown(e)}>
              <div className="create-ad-part flex-col">
                <h3 className="xs:text-base mb-[14px]">상호명(또는 성함)을 입력해주세요</h3>
                <FormTextboxGroup
                  name="name"
                  autoComplete="off"
                  validators={{
                    required: true,
                  }}
                  validations={['required']}
                />
                <div className="mt-6" />
                <h3 className="xs:text-base mb-[14px]">연락처를 입력해주세요</h3>
                <FormTextboxGroup
                  name="phone_no"
                  autoComplete="off"
                  placeholder="01012345678"
                  validators={{
                    required: true,
                    pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                  }}
                  validations={['required', 'pattern']}
                />
                <div className="mt-6" />
                <h3 className="xs:text-base mb-[14px]">상담 희망 영역</h3>
                <div>
                  <Radio
                    value="iptv"
                    className="font-[500]"
                    {...methods.register('category')}
                    onSelect={(e) => methods.setValue('category', 'iptv')}
                  >
                    IPTV (B tv 우리동네 광고)
                  </Radio>
                  <Radio
                    value="cabletv"
                    className="font-[500]"
                    {...methods.register('category')}
                    onSelect={(e) => methods.setValue('category', 'cabletv')}
                  >
                    케이블TV 지역 광고
                  </Radio>
                  <Radio
                    value="iptv+cabletv"
                    className="font-[500]"
                    {...methods.register('category')}
                    onSelect={(e) => methods.setValue('category', 'iptv+cabletv')}
                  >
                    IPTV + 케이블TV 결합 광고
                  </Radio>
                </div>
                <div className="mt-12 w-full">
                  <h3 className="xs:text-base mb-[8px]">상담을 희망하는 날짜를 선택해주세요</h3>
                  <div className="flex flex-col lg:flex-row lg:mt-8">
                    <div className="flex flex-col items-start lg:flex-1">
                      <p className="text-sm">Step 1. 날짜</p>
                      {renderCalendar({
                        disabledDay: (tempDate) => {
                          const dayOfWeek = tempDate.getDay()
                          return dayOfWeek === 6 || dayOfWeek === 0
                        },
                        disabledDates: holidays.map((item) => new Date(item)),
                      })}
                      <p className="text-[#f22] text-sm mt-2">※ 주말 및 공휴일은 제외하고 선택해 주세요</p>
                    </div>
                    <div className="flex flex-col items-stretch mt-[16px] lg:mt-0 lg:ml-4 lg:flex-1">
                      <p className="text-sm">Step 2. 시간</p>
                      {renderTimeSelector()}
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-8">
                  <FormCheckbox textClassName="text-[16px]" name="agree_privacy" validators={{ required: true }}>
                    개인정보 수집 및 이용 동의
                  </FormCheckbox>
                  <a
                    className="smaller right-icon cursor-pointer mb-2.5 ml-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={CONFIG.personalInfoTermURLConsult}
                  >
                    약관보기
                    <img className="w-4 ml-1" src={targetRight} alt="target" />
                  </a>
                </div>
              </div>
              <div className="flex mmd:!flex-col-reverse ad-buttons-holder justify-center mlg:!bg-white mlg:mt-[-8px] mlg:!mb-12">
                <button
                  type="cancel"
                  className="secondary larger rounded mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:!text-base"
                  onClick={onClickBack}
                >
                  이전으로
                </button>
                <button
                  type="submit"
                  className="primary larger mmd:rounded-[10px] mmd:!py-[5px] mmd:!min-h-[50px] mmd:!mb-4 mmd:rounded-br-none mmd:!text-base"
                  disabled={!phone_no || !agree_privacy || !selectedTime}
                >
                  예약 신청하기
                </button>
              </div>
            </form>
          </FormProvider>
        </Container>
        <MessageModal
          type="success"
          isShown={doneModalInfo.isShown}
          onClose={() => {
            history.replace('/')
          }}
        >
          <h3>{doneModalInfo.title}</h3>
        </MessageModal>
      </div>
    </BasicLayout>
  )
}

export default Consult
