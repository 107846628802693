import React from 'react'
import { Container } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useLocation, useParams } from 'react-router-dom'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

import NavigationLayout from '../../layouts/navigationLayout'
import CommentWrapper from './components/commentWrapper'
import AppContext from '../../services/context'
import useCommentWritter from './hooks/useCommentWritter'

import { postCommunityComment } from '../../services/api'
import { hideFab } from '../../services/fab'
//
const CommentPage = () => {
  const [cookies] = useCookies(['jwt_token'])
  const { appState } = React.useContext(AppContext)
  const location = useLocation()
  const post = location.state
  const params = useParams()
  hideFab()
  const [commentList, setCommentList] = React.useState(post?.comment)
  const [writter, renderWritterInput, initWritterInput] = useCommentWritter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [comment, setComment] = React.useState('')
  //
  const onInputChange = async (e) => {
    await setComment(e.target.value)
  }
  //
  const onCommentClick = async () => {
    if (!comment) return
    let result = null
    if (appState.userInfo === null) {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }
      const token = await executeRecaptcha('comment')
      result = await postCommunityComment(
        null,
        params.boardId,
        post._id,
        comment,
        token,
        writter,
      )
      initWritterInput()
    } else {
      result = await postCommunityComment(
        cookies.jwt_token,
        params.boardId,
        post._id,
        comment,
      )
    }
    if (!result) return
    result.user = {
      _id: result.user,
      userId: appState.userInfo?.userId,
    }
    await setCommentList((prev) => prev.concat([result]))
    await setComment('')
  }
  //
  return (
    <NavigationLayout navigationTitle="댓글">
      <Container>
        <div className="comment-page">
          {!appState.userInfo?.userId ? (
            renderWritterInput()
          ) : (
            <div className="commenter-id">{appState.userInfo?.userId}</div>
          )}
          <div className="textbox-action flex flex-row">
            <textarea
              onChange={onInputChange}
              className="flex-grow large"
              placeholder="댓글을 남겨주세요."
              value={comment}
              rows={1}
            />
            <button
              className="large dark"
              onClick={onCommentClick}
              disabled={comment === ''}
            >
              등록
            </button>
          </div>
          <div className="comments-section">
            {commentList
              && commentList.map((item, index) => (
                <CommentWrapper
                  key={index}
                  commentData={item}
                  postId={post._id}
                />
              ))}
          </div>
          {commentList && commentList.length === 0 && (
            <div className="comments-section-empty">
              <span>댓글이 없습니다.</span>
            </div>
          )}
        </div>
      </Container>
    </NavigationLayout>
  )
}

const CaptchaWrapped = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeJdeAcAAAAAHBYBocoZ5czU4gCIWoLPx9KWbtS"
    useEnterprise
  >
    <CommentPage />
  </GoogleReCaptchaProvider>
)

export default CaptchaWrapped
