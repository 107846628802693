import React from 'react'
import { Container, Table } from 'react-bootstrap'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import LoadingIndicator from '../../components/LoadingIndicator'
import usePageIndicator from '../../hooks/usePageIndicator'
import AppContext from '../../services/context'
import BasicLayout from '../../layouts/basic'
import { getCommunityPosts } from '../../services/api'
import usePasswordModal from './hooks/usePasswordModal'
import ReIcon from '../../assets/icons/re_icon.png'

const EventPost = ({ post, onClick }) => {
  const date = new Date(post.createdAt)
  return (
    <tr className={`text-[10px] lg:text-base cursor-pointer ${post.fixed ? 'bg-[#f0f0f0]' : ''}`} onClick={onClick}>
      <td className="text-center whitespace-nowrap mlg:!px-[10px] mlg:!py-[16px]">
        {post.fixed ? '공지' : post.no || -1}
      </td>
      <td className="mlg:!px-[10px] mlg:!py-[16px]">
        <div className="flex flex-col items-start gap-2 lg:flex-row lg:items-center">
          {post.title.includes('RE:') || post.isReply ? (
            <>
              <img src={ReIcon} alt="re:" className="w-[20px] lg:w-[40px] mb-[-2px] ml-2 lg:ml-4" />
              <span className="ml-2 lg:ml-0">{post.title}</span>
            </>
          ) : (
            post.title
          )}
        </div>
      </td>
      <td className="text-center mlg:!px-[10px] mlg:!py-[16px]">{post.store}</td>
      <td className="text-center mlg:!px-[10px] mlg:!py-[16px]">{post.name}</td>
      <td className="text-center mlg:!px-[10px] mlg:!py-[16px]">
        {`${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`}
      </td>
    </tr>
  )
}

const CommunityBoard = () => {
  const [posts, setPosts] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const { appState } = React.useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const { pageOption, setPageOption, renderPageIndicator } = usePageIndicator({
    currentPage: location.state?.currentPage || 1,
    pageCount: 0,
  })
  //
  React.useEffect(() => {
    (async () => {
      setLoading(true)
      const result = await getCommunityPosts(params.boardId, pageOption?.currentPage)
      setLoading(false)
      if (!result) return
      setPageOption({
        ...pageOption,
        pageCount: result.pageCount,
      })
      if (result.result) {
        setPosts(result.result)
      }
    })()
  }, [pageOption?.currentPage])
  //
  const checkPasswordCallback = React.useCallback(
    (postId, code) => {
      if (code === 'SUCCESS') {
        history.push(`/board/${params.boardId}/post/${postId}`, {
          currentPage: pageOption?.currentPage,
        })
      } else {
        enqueueSnackbar('비밀번호가 맞지 않습니다', { variant: 'error' })
      }
    },
    [history, pageOption?.currentPage, enqueueSnackbar, params],
  )
  const { open, renderPasswordModal } = usePasswordModal()
  //
  const onClickItem = React.useCallback(
    (post) => {
      const href = `/board/${params.boardId}/post/${post._id}`
      if (post.visible || appState.userInfo?._id === post.user) {
        history.push(href, { currentPage: pageOption?.currentPage })
      } else if (post.user) {
        enqueueSnackbar('비공개 게시물입니다', { variant: 'error' })
      } else {
        open(post, checkPasswordCallback)
      }
    },
    [history, pageOption?.currentPage],
  )
  //
  const onClickWrite = React.useCallback(() => {
    history.push(`/board/${params.boardId}/edit`)
  }, [history])
  //
  return (
    <BasicLayout className="bg-white mlg:pb-6">
      <Container className="notices py-10">
        {/* <div className="flex justify-end mlg:mr-4">
          <button className="primary mb-4" onClick={onClickWrite}>
            작성하기
          </button>
        </div> */}
        <Table responsive>
          <thead>
            <tr className="text-[10px] lg:text-base" valign="middle">
              <th className="w-[50px] lg:w-[70px] text-center">#</th>
              <th>제목</th>
              <th className="w-[70px] lg:w-[100px] text-center">매장명</th>
              <th className="w-[70px] lg:w-[100px] text-center">작성자</th>
              <th className="w-[70px] lg:w-[100px] text-center">게시일</th>
            </tr>
          </thead>
          <tbody>
            {posts.length > 0
              && posts.map((post) => <EventPost key={post._id} post={post} onClick={() => onClickItem(post)} />)}
          </tbody>
        </Table>
        <div className="flex justify-center items-center mt-[105px]">{renderPageIndicator()}</div>
      </Container>
      {renderPasswordModal()}
      {loading && <LoadingIndicator />}
    </BasicLayout>
  )
}

export default CommunityBoard
