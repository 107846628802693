/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'

import AppContext from '../../services/context'
import MyPageLayout from '../../layouts/myPageLayout'
import {
  fetchContracts,
  updateContractStatus,
  fetchContractUserInfo,
  requestAuth,
} from '../../services/api'
import imgClose from '../../assets/icons/icon_outlined_suggested_close-circle_medium.svg'
//
const statusData = {
  wait: {
    title: '대기',
    color: 'var(--success-color)',
  },
  accepted: {
    title: '승인',
    color: 'var(--primary-color)',
  },
  rejected: {
    title: '거절/삭제',
    color: 'var(--error-color)',
  },
}
//
const BusinessManagement = () => {
  const { appState } = React.useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const [cookies] = useCookies(['jwt_token'])
  const [userId, setUserId] = React.useState('')
  const [data, setData] = React.useState({
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
  })
  const [agencyData, setAgencyData] = React.useState([])
  const [findUserData, setFindUserData] = React.useState(null)
  //
  const fetchContractData = async () => {
    const result = await fetchContracts(
      cookies.jwt_token,
      Number(appState?.userInfo?.companyRank),
      appState?.userInfo?.company?._id,
    )
    if (!result) return
    const tab1Data = []
    const tab2Data = []
    const tab3Data = []
    const tab4Data = []
    //
    result.forEach((item) => {
      tab1Data.push(item)
      if (item.status === 'accepted') {
        tab2Data.push(item)
      } else if (item.status === 'wait') {
        tab3Data.push(item)
      } else {
        tab4Data.push(item)
      }
    })
    await setData({
      tab1: tab1Data,
      tab2: tab2Data,
      tab3: tab3Data,
      tab4: tab4Data,
    })
  }
  const fetchAgencyData = async () => {
    const result = await fetchContracts(
      cookies.jwt_token,
      Number(appState?.userInfo?.companyRank),
      appState?.userInfo?.company?._id,
    )
    if (!result) return
    setAgencyData(result)
  }
  //
  React.useEffect(() => {
    if (Number(appState.userInfo?.userType) === 0) {
      fetchContractData()
    } else {
      fetchAgencyData()
    }
  }, [])
  //
  const onClickReject = (contractId) => {
    updateContractStatus(cookies.jwt_token, {
      contractId,
      status: 'rejected',
    }).then(() => {
      fetchContractData()
      enqueueSnackbar('거절/삭제 처리되었습니다', { variant: 'success' })
    })
  }
  const onClickAccept = (contractId) => {
    updateContractStatus(cookies.jwt_token, {
      contractId,
      status: 'accepted',
    }).then((result) => {
      if (result.code === 'SUCCESS') {
        fetchContractData()
        enqueueSnackbar('승인 처리되었습니다', { variant: 'success' })
      } else if (result.message) {
        enqueueSnackbar(result.message, { variant: 'error' })
      }
    })
  }
  const onChangeUserId = (e) => {
    setUserId(e.target.value)
  }
  const onClickSearch = () => {
    if (userId === appState.userInfo.userId) {
      enqueueSnackbar('내 아이디입니다', { variant: 'error' })
      return
    }
    fetchContractUserInfo(cookies.jwt_token, userId).then((result) => {
      if (result.code === 'SUCCESS') {
        setFindUserData(result.data)
      } else if (result.message) {
        enqueueSnackbar(result.message, { variant: 'error' })
      }
    })
  }
  const onClickClose = () => {
    setFindUserData(null)
  }
  const onClickRequest = () => {
    requestAuth(cookies.jwt_token, findUserData._id).then((result) => {
      if (result.code === 'SUCCESS') {
        enqueueSnackbar('신청되었습니다', { variant: 'success' })
        fetchAgencyData()
        setFindUserData(null)
      }
    })
  }
  //
  return (
    <MyPageLayout navigationTitle="비즈니스 관리" isHeaderHiddenInMobile>
      <div className="hidden header lg:block">
        <h3>비즈니스 관리</h3>
      </div>
      <div className="business-page">
        {Number(appState.userInfo?.userType) === 0 ? (
          <div className="flex flex-col">
            {data.tab3.map((item, index) => (
              <div
                key={index}
                className="flex items-center w-full request-wrapper"
              >
                <span className="w-full">{`${item.business?.company?.companyName} - ${item.business?.userName}\n님의 권한 요청`}</span>
                <div className="flex flex-col lg:flex-row">
                  <button
                    className="button border bg-white"
                    style={{ color: 'var(--text-secondary)' }}
                    onClick={() => onClickReject(item._id)}
                  >
                    거절하기
                  </button>
                  <button
                    className="button primary"
                    onClick={() => onClickAccept(item._id)}
                  >
                    수락하기
                  </button>
                </div>
              </div>
            ))}
            {data.tab3.length === 0 && (
              <div className="flex items-center w-full request-wrapper">
                <span className="w-full">권한 요청이 없습니다.</span>
              </div>
            )}
          </div>
        ) : (
          <div style={{ marginTop: 44 }}>
            <h3>광고주 권한 요청</h3>
            <div className="flex">
              <input
                className="larger w-full"
                placeholder="ID를 입력하세요"
                onChange={onChangeUserId}
              />
              <button
                className="button dark whitespace-nowrap ml-4 pl-8 pr-8"
                onClick={onClickSearch}
              >
                검색
              </button>
            </div>
            {findUserData && (
              <div className="flex w-full relative request-wrapper mt-8">
                <div className="flex flex-col find-user-info">
                  <span>ID</span>
                  <span>이름</span>
                  <span>휴대전화</span>
                  <span>회사</span>
                </div>
                <div className="flex flex-col ml-2 find-user-info">
                  <span>
                    :&nbsp;&nbsp;
                    {findUserData?.userId}
                  </span>
                  <span>
                    :&nbsp;&nbsp;
                    {findUserData?.userName}
                  </span>
                  <span>
                    :&nbsp;&nbsp;
                    {findUserData?.userPhonenumber}
                  </span>
                  <span>
                    :&nbsp;&nbsp;
                    {findUserData?.company?.companyName}
                  </span>
                </div>
                <div className="close-button" onClick={onClickClose}>
                  <img src={imgClose} alt="닫기" />
                </div>
                <button
                  className="button primary absolute right-4 bottom-4"
                  style={{ color: 'white' }}
                  onClick={onClickRequest}
                >
                  권한요청
                </button>
              </div>
            )}
          </div>
        )}
        <div className="divider" />
        {/**/}
        {Number(appState.userInfo?.userType) === 0 ? (
          <div className="list-section">
            {/**/}
            <Tabs className="tabs-primary" selectedTabClassName="active">
              <TabList className="flex tab-list-primary">
                <Tab className="px-4 tab-primary lg:px-8">전체</Tab>
                <Tab className="px-4 tab-primary lg:px-8">승인</Tab>
                <Tab className="px-4 tab-primary lg:px-8">대기</Tab>
                <Tab className="px-4 tab-primary lg:px-8">거절/삭제</Tab>
                <div className="hidden flex-grow lg:block" />
              </TabList>
              {/**/}
              <TabPanel>
                <TabPanelView data={data.tab1} />
              </TabPanel>
              <TabPanel>
                <TabPanelView data={data.tab2} />
              </TabPanel>
              <TabPanel>
                <TabPanelView data={data.tab3} />
              </TabPanel>
              <TabPanel>
                <TabPanelView data={data.tab4} />
              </TabPanel>
            </Tabs>
          </div>
        ) : (
          <div>
            <Tabs className="tabs-primary" selectedTabClassName="active">
              <TabList className="flex tab-list-primary">
                <Tab className="px-4 tab-primary lg:px-8">보유 광고주</Tab>
                <div className="hidden flex-grow lg:block" />
              </TabList>
              {/**/}
              <TabPanel>
                <AgencyTabPanelView
                  data={agencyData}
                  onClickReject={onClickReject}
                />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
    </MyPageLayout>
  )
}
//
const TabPanelView = ({ data }) => {
  const [tableData, setTableData] = React.useState(data)
  //
  React.useEffect(() => {
    setTableData(data)
  }, [setTableData, data])
  //
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>광고 관리</th>
            <th>광고 담당자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody className="business-table">
          {tableData.length > 0
            && tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.business?.company?.companyName}</td>
                <td>{item.business?.userName}</td>
                <td style={{ color: statusData[item.status]?.color }}>
                  {statusData[item.status]?.title}
                </td>
              </tr>
            ))}
          {tableData.length === 0 && (
            <tr
              className="empty"
              style={{ borderLeftWidth: 0, borderBottomWidth: 0 }}
            >
              <td colSpan="6">내역이 없습니다</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="flex flex-col lg:hidden">
        {tableData.map((item, index) => (
          <div key={index} className="flex flex-col table-mobile-items">
            <div className="flex justify-between">
              <span>광고 관리</span>
              <span>{item.business?.company?.companyName}</span>
            </div>
            <div className="flex justify-between">
              <span>광고 담당자</span>
              <span>{item.business?.userName}</span>
            </div>
            <div>
              <span style={{ color: statusData[item.status]?.color }}>
                {statusData[item.status]?.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const AgencyTabPanelView = ({ data, onClickReject }) => {
  const [tableData, setTableData] = React.useState(data)
  //
  React.useEffect(() => {
    setTableData(data)
  }, [setTableData, data])
  //
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>광고주 ID</th>
            <th>이름</th>
            {/* <th>{"현재 캠페인"}</th> */}
            <th>등록일</th>
            <th>액션</th>
          </tr>
        </thead>
        <tbody className="business-table">
          {tableData.length > 0
            && tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.user?.userId}</td>
                <td>{item.user?.userName}</td>
                {/* <td>{"현재 캠페인"}</td> */}
                <td>{moment(item.createAt).format('YYYY.MM.DD')}</td>
                <td>
                  <button
                    className="button border bg-white table-button"
                    style={{ color: 'var(--text-secondary)' }}
                    onClick={() => onClickReject(item._id)}
                  >
                    삭제하기
                  </button>
                </td>
              </tr>
            ))}
          {tableData.length === 0 && (
            <tr
              className="empty"
              style={{ borderLeftWidth: 0, borderBottomWidth: 0 }}
            >
              <td colSpan="6">내역이 없습니다</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="flex flex-col lg:hidden">
        {tableData.map((item, index) => (
          <div key={index} className="flex flex-col table-mobile-items">
            <div className="flex justify-between">
              <span>{item.user?.userId}</span>
              <span>{item.user?.userName}</span>
            </div>
            <div className="flex justify-between">
              {/* <span>{"현재 캠페인"}</span> */}
              <span>{moment(item.createAt).format('YYYY.MM.DD')}</span>
            </div>
            <div>
              <button
                className="button border bg-white"
                style={{ color: 'var(--text-secondary)' }}
                onClick={() => onClickReject(item._id)}
              >
                삭제하기
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default BusinessManagement
