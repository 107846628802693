import React from 'react'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

import AppContext from '../../../services/context'
import {
  editCommunityComment,
  editCommunitySubComment,
} from '../../../services/api'
//
const Comment = ({
  postId,
  commentData = {},
  isSubCmnt = false,
  onClickDelete,
}) => {
  const { appState } = React.useContext(AppContext)
  const [cookies] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  //
  const [isEditing, setIsEditing] = React.useState(false)
  const [comment, setComment] = React.useState(commentData?.contents)
  const [editingComment, setEditingComment] = React.useState('')
  //
  const onCommentChange = async (e) => {
    await setEditingComment(e.target.value)
  }
  const onClickEdit = () => {
    setIsEditing((prev) => !prev)
  }
  const onClickEditDone = async () => {
    const editApi = isSubCmnt ? editCommunitySubComment : editCommunityComment
    await editApi(
      cookies.jwt_token,
      params.boardId,
      postId,
      commentData._id,
      editingComment,
    )
    setComment(editingComment)
    enqueueSnackbar('수정이 완료되었습니다', { variant: 'success' })
    setIsEditing(false)
  }
  //
  React.useEffect(() => {
    (async () => {
      if (appState.userInfo === null) return
      if (commentData.contents) setEditingComment(commentData.contents)
    })()
  }, [appState.userInfo, commentData.user?._id, commentData.contents])
  //
  let containerClassName = 'comment flex flex-col'
  if (isSubCmnt) containerClassName += ' sub-comment'
  const owner = !!commentData.user?._id && commentData.user?._id === appState.userInfo?.id
  return (
    <div className={containerClassName}>
      <div className="flex justify-between">
        <span className={`author${owner ? ' active' : ''}`}>
          {commentData.user?.userId || commentData.userName || '익명'}
        </span>
        {owner && (
          <div className="flex comment-edit">
            <span
              style={{ color: 'var(--text-secondary)' }}
              onClick={onClickEdit}
            >
              수정
            </span>
            <span className="btn-delete" onClick={onClickDelete}>
              삭제
            </span>
          </div>
        )}
      </div>
      {isEditing ? (
        <div
          className="add-comment-section textbox-action flex flex-row"
          style={{ marginBottom: 16 }}
        >
          <textarea
            onChange={onCommentChange}
            className="flex-grow large"
            placeholder="댓글을 남겨주세요."
            value={editingComment}
            rows={1}
          />
          <button
            className="large dark"
            onClick={async (e) => onClickEditDone(e)}
            disabled={editingComment === ''}
          >
            수정
          </button>
        </div>
      ) : (
        <p className="content">{comment}</p>
      )}
      <span className="date" style={{ marginBottom: '16px' }}>
        {moment(commentData.createdAt).format('YYYY.MM.DD')}
      </span>
    </div>
  )
}

export default Comment
