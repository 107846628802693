import React from 'react'
import { Container } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'

import AppContext from '../../services/context'
import { CreateAdCard } from '../../components/adCard'
import { ContentModal } from '../../components/modals'
import { getAssets, uploadAsset } from '../../services/api'
import { CONFIG } from '../../services/config'
import AssetCard from './components/assetCard'
import useChangeNameModal from './hooks/useChangeNameModal'
import imgSearch from '../../assets/icons/icon-outlined-application-search-large-text-3.svg'
import AssetProgressText from './components/assetProgressText'
import { ASSET_UPLOAD_MESAGES } from '../../services/constants'
//
const filterArr = ['all', 'template', 'done', 'upload']
//
const AdCampaignStep2 = () => {
  const { appState } = React.useContext(AppContext)
  const history = useHistory()
  const [cookies] = useCookies(['jwt_token'])
  const inputRef = React.useRef(null)
  const observer = React.useRef()
  const { enqueueSnackbar } = useSnackbar()

  const [assets, setAssets] = React.useState([])
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
  const [selectedMenu, setSelectedMenu] = React.useState(null)
  const [order, setOrder] = React.useState('updatedAt')
  const [search, setSearch] = React.useState('')
  const [submittedSearch, setSubmittedSearch] = React.useState('')
  const [modalVisible, setModalVisible] = React.useState(false)
  const [progressMsgs, setProgressMsgs] = React.useState([])
  const [uploadStatus, setUploadStatus] = React.useState('progress')
  const [page, setPage] = React.useState(1)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  //
  React.useEffect(() => {
    const onClickOutside = async (e) => {
      if (e.path) {
        const isMenu = e.path.some((item) => item?.className === 'menu-wrapper')
        if (isMenu) return
      }
      const openedMenu = window.document.getElementsByClassName('menu-holder show')
      if (openedMenu.length > 0) {
        openedMenu[0].classList.remove('show')
      }
    }
    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [])
  //
  React.useEffect(() => {
    // console.log('EventSource connection on')
    // console.log(`${CONFIG.API_ENDPOINT}/media/progress`)
    // console.log(appState?.userInfo?.id)
    const listener = (event) => {
      const eventData = JSON.parse(event.data)
      if (eventData.code !== 'progress') {
        setUploadStatus(eventData.code)
      }
      setProgressMsgs((old) => {
        if (old.length === 0) {
          return [eventData.msg]
        }
        if (old.some((item) => item === eventData.msg)) {
          return old
        }
        return [...old, eventData.msg]
      })
    }
    const es = new EventSource(`${CONFIG.API_ENDPOINT}/media/progress`)

    // es.onmessage = function (event) {
    //   console.log(event.data)
    // }

    // USER ID
    es.addEventListener(appState?.userInfo?.id, listener)
    return () => {
      console.log('connection close')
      es.removeEventListener(appState?.userInfo?.id, listener)
      es.close()
    }
  }, [appState?.userInfo?.id])
  //
  const initializeList = React.useCallback(async () => {
    await setAssets([])
    await setPage(1)
    await setHasNextPage(false)
  }, [])
  //
  const onSort1Click = React.useCallback(() => {
    setOrder((prev) => {
      if (prev === 'updatedAt') return prev
      initializeList()
      return 'updatedAt'
    })
  }, [initializeList])
  const onSort2Click = React.useCallback(() => {
    setOrder((prev) => {
      if (prev === 'createdAt') return prev
      initializeList()
      return 'createdAt'
    })
  }, [initializeList])
  //
  const onMakingAssetsClick = () => {
    history.push('/portal/media/create/1/assets')
  }
  //
  const onLoadFile = () => {
    if (inputRef.current) inputRef.current.click()
  }
  //
  const handleFileinput = (e) => {
    if (e.target.files[0].size > 50000000) {
      enqueueSnackbar('50MB가 넘는 파일은 업로드 할 수 없습니다', { variant: 'warning' })
      e.target.value = ''
      return
    }
    setProgressMsgs([])
    setUploadStatus('progress')
    setModalVisible(true)

    uploadAsset(cookies.jwt_token, e.target.files[0]).then((result) => {
      if (inputRef.current) inputRef.current.value = null
      if (result) {
        setAssets((old) => [...old, result.data])
      }
    })
  }
  const fetchAssets = React.useCallback(async () => {
    const result = await getAssets(cookies.jwt_token, page, order, filterArr[selectedTabIndex], search)
    if (!result) return
    await setHasNextPage(result.hasNextPage)
    if (page === 1) {
      await setAssets(result.docs)
    } else {
      await setAssets((prev) => [...prev, ...result.docs])
    }
  }, [cookies.jwt_token, page, order, submittedSearch, selectedTabIndex, setHasNextPage])
  //
  React.useEffect(() => {
    fetchAssets()
  }, [fetchAssets])
  //
  const onClickMenu = (id) => {
    if (selectedMenu === id) setSelectedMenu(null)
    else setSelectedMenu(id)
  }
  const onClickTab = (index) => () => {
    initializeList()
    setSelectedTabIndex(index)
  }
  const changeNameCallback = (id, value) => {
    const newArr = [...assets]
    const i = newArr.findIndex((item) => item._id === id)
    newArr[i].assetName = value
    setAssets(newArr)
  }
  const { onClickChangeName, renderChangeNameModal } = useChangeNameModal('asset', changeNameCallback)
  const onChangeOrder = React.useCallback(
    (e) => {
      if (e.target.value === '0') {
        onSort2Click()
      } else {
        onSort1Click()
      }
    },
    [onSort1Click, onSort2Click],
  )

  const onChangeSearch = React.useCallback((e) => {
    setSearch(e.target.value)
  }, [])

  const onClickSearch = React.useCallback(() => {
    initializeList()
    setSubmittedSearch(search)
  }, [initializeList, search])

  const onDeleteAsset = React.useCallback((id) => {
    setAssets((old) => old.filter((item) => item._id !== id))
  }, [])

  const lastElementRef = React.useCallback(
    (node) => {
      // if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          setPage((prev) => prev + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasNextPage],
  )

  const getTabClassName = React.useCallback(
    (index) => `tab-secondary px-8${index === selectedTabIndex ? ' active' : ''}`,
    [selectedTabIndex],
  )

  return (
    <Container>
      <div className="media-tabs tabs-secondary">
        <ul className="flex tab-list-secondary">
          <li className={getTabClassName(0)}>
            <div onClick={onClickTab(0)}>모든 소재</div>
          </li>
          <li className={getTabClassName(1)}>
            <div onClick={onClickTab(1)}>소재 제작</div>
          </li>
          <li className={getTabClassName(2)}>
            <div onClick={onClickTab(2)}>제작 완료</div>
          </li>
          <li className={getTabClassName(3)}>
            <div onClick={onClickTab(3)}>내 컴퓨터</div>
          </li>
          <div className="hidden flex-grow xl:block" />
          <div className="hidden xl:block">
            <Link to="/tutorial/asset">
              <button className="tutorial">소재 제작 방법 보기</button>
            </Link>
          </div>
        </ul>
        <div className="flex flex-col notice-box xl:hidden">
          <p>꼭 알아두세요!</p>
          <p>소재 제작은 컴퓨터에서만 가능합니다</p>
        </div>
        <div className="flex justify-between sort-commands space-x-1">
          <div className="textbox-wrapper relative flex-1 sm:max-w-[351px]">
            <input
              className="w-full rounded-0 lg:text-md"
              type="text"
              value={search || ''}
              placeholder="소재명 검색"
              onChange={onChangeSearch}
              onKeyUp={(e) => {
                if (e.keyCode === 13) onClickSearch()
              }}
            />
            <button className="absolute p-0 right-2 top-[11px]" onClick={onClickSearch}>
              <img src={imgSearch} alt="search" />
            </button>
          </div>
          <div className="hidden lg:flex">
            <button className="light order-button" onClick={onSort2Click}>
              최초 생성일 순
            </button>
            <div className="divider-vertical" />
            <button className="light order-button" onClick={onSort1Click}>
              최근 수정일 순
            </button>
          </div>
          <div className="self-center lg:hidden">
            <select onChange={onChangeOrder}>
              <option value="0">최초 생성일 순</option>
              <option value="1">최근 수정일 순</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 ad-campaign-list">
          {(selectedTabIndex === 0 || selectedTabIndex === 1) && (
            <CreateAdCard
              href="https://skb-public.s3.ap-northeast-2.amazonaws.com/AssetGuide.pdf"
              toText="소재 가이드라인 확인하기"
              title="소재 만들기"
              buttonText="새로운 소재 만들기"
              onClick={onMakingAssetsClick}
            >
              <p className="flex-grow">
                <span>광고 소재가 없다면 직접 만들어보세요.</span>
                <br />
                <span>가장 간단한 방법으로 만들 수 있어요.</span>
              </p>
            </CreateAdCard>
          )}
          {/**/}
          {(selectedTabIndex === 0 || selectedTabIndex === 3) && (
            <CreateAdCard
              href="https://skb-public.s3.ap-northeast-2.amazonaws.com/AssetGuide.pdf"
              toText="소재 가이드라인 확인하기"
              title="내 컴퓨터에서 불러오기"
              buttonText="내 컴퓨터에서 불러오기"
              onClick={onLoadFile}
            >
              <p className="flex-grow">
                외부에서 제작한 소재를 불러올 수 있어요.
                <br />
                <span className="text-sm text-textSecondary">※ 업로드 파일사이즈는 50MB 이하</span>
              </p>
            </CreateAdCard>
          )}
          {assets.map((item, index) => {
            const isLastElement = assets.length === index + 1
            return (
              <AssetCard
                key={index}
                data={item}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                onClickChangeName={onClickChangeName}
                onDeleteAsset={onDeleteAsset}
                ref={isLastElement ? lastElementRef : null}
              />
            )
          })}
        </div>
        <ContentModal
          id="upload_asset"
          title="내 컴퓨터에서 불러오기"
          isShown={modalVisible}
          style={{ padding: '36px 16px' }}
          onClose={() => {
            if (page > 1) setPage(1)
            else fetchAssets()
            setModalVisible(false)
          }}
          disabled={uploadStatus === 'progress'}
          buttonText={uploadStatus === 'progress' ? '업로드 중...' : '확인'}
          isStatic
        >
          <ul className="progress-list space-y-3">
            {ASSET_UPLOAD_MESAGES.map((item, index) => (
              <AssetProgressText key={index} messages={progressMsgs} category={item} />
            ))}
          </ul>
        </ContentModal>
      </div>
      <input
        ref={inputRef}
        type="file"
        accept="image/*,video/*"
        name="video"
        onChange={handleFileinput}
        style={{ display: 'none' }}
      />
      {renderChangeNameModal()}
    </Container>
  )
}

export default AdCampaignStep2
