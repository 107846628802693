import React from 'react'
import classNames from 'classnames'

import playIcon from '../../../assets/icons/icon-play.svg'
import pauseIcon from '../../../assets/icons/icon-pause.svg'
import illust_m0 from '../../../assets/imgs/illust_voice_m0.png'
import illust_m1 from '../../../assets/imgs/illust_voice_m1.png'
import illust_m2 from '../../../assets/imgs/illust_voice_m2.png'
import illust_f0 from '../../../assets/imgs/illust_voice_f0.png'
import illust_f1 from '../../../assets/imgs/illust_voice_f1.png'
import illust_f2 from '../../../assets/imgs/illust_voice_f2.png'

export const characters = [
  {
    id: '618b1849ef7827cfea34ea1e',
    name: '한준',
    image: illust_m0,
    sampleAudio: '/audios/sample_m0.mp3',
  },
  {
    id: '6215be66c55ef259159b2b39',
    name: '혜수',
    image: illust_f0,
    sampleAudio: '/audios/sample_f0.mp3',
  },
  {
    id: '60a3c91b4bc87f0d62b09a50',
    name: '지희',
    image: illust_f1,
    sampleAudio: '/audios/sample_f1.mp3',
  },
  {
    id: '6050baed630c0d0906e65cc5',
    name: '쇼린',
    image: illust_f2,
    sampleAudio: '/audios/sample_f2.mp3',
  },
  {
    id: '6050bb4a630c0d0906e65d5d',
    name: '성준',
    image: illust_m1,
    sampleAudio: '/audios/sample_m1.mp3',
  },
  {
    id: '648aaee9248bcd37dad435e6',
    name: '무열',
    image: illust_m2,
    sampleAudio: '/audios/sample_m2.mp3',
  },
]

export const defaultCharacter = characters[0].id

const SelectCharacter = ({ character, onClickCharacter }) => {
  const [playingId, setPlayingId] = React.useState(false)
  const audio = React.useRef()

  const playSampleAudio = (id, source) => {
    if (audio.current) {
      if (playingId === id) {
        setPlayingId(null)
        audio.current.pause()
      } else {
        setPlayingId(id)
        audio.current.src = source
        audio.current.play()
      }
    }
  }

  return (
    <div className="flex gap-2">
      {characters.map((item) => {
        const selected = item.id === character
        const divClassNames = classNames('flex flex-col flex-center w-[88px] h-[99px] border-[1px] rounded cursor-pointer', {
          'border-main': selected,
          'border-line': !selected,
        })
        return (
          <div key={item.id} className={divClassNames} onClick={() => onClickCharacter(item.id)}>
            <div className="relative">
              <div className="w-[55px] h-[55px] mb-1 rounded-full overflow-hidden">
                <img className="w-full h-full" src={item.image} alt="illust" />
              </div>
              <div className="absolute right-0 bottom-0 w-[19px] h-[19px]" onClick={() => playSampleAudio(item.id, item.sampleAudio)}>
                {playingId === item.id ? <img src={pauseIcon} alt="pause" /> : <img src={playIcon} alt="play" />}
              </div>
              <audio ref={audio} className="hidden" src={item.sampleAudio} />
            </div>
            <span className="text-textSecondary font-medium">{item.name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default SelectCharacter
