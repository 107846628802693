import React from 'react'
import { Container, Carousel } from 'react-bootstrap'
import BasicLayout from '../../layouts/basic'
import AppContext from '../../services/context'
import icon1 from '../../assets/imgs/9519.svg'
import icon2 from '../../assets/imgs/9520.svg'

const SuccessStoryPage = () => {
  const { appState } = React.useContext(AppContext)
  const story = appState.currentStory
  //
  return (
    <BasicLayout>
      <div className="success-story">
        <div
          className="section-1 flex justify-center flex-col"
          style={{
            backgroundImage: `url(${story.header.img})`,
          }}
        >
          <h1>{story.header.title}</h1>
        </div>
        <div
          className="section-2"
          style={{ backgroundColor: story.description.backgroundColor }}
        >
          <Container className="grid md:grid-cols-2 gap-8">
            <div>
              <h2>{story.description.title}</h2>
              <h3>{story.description.subTitle}</h3>
              <p>{story.description.content}</p>
            </div>
            <div>
              <img src={story.description.img} className="w-full" alt="media" />
            </div>
          </Container>
        </div>
        <div
          className="section-3"
          style={{ backgroundColor: story.features.backgroundColor }}
        >
          <Container>
            <div className="flex flex-col text-center items-center">
              <h2>{story.features.title}</h2>
              <p className="subtitle">{story.features.subTitle}</p>
              <div className="grid md:grid-cols-2 gap-18">
                <div>
                  <img src={icon1} alt="media" />
                  <p>{story.features.icon_1.title}</p>
                  <p className="pr">{story.features.icon_1.subTitle}</p>
                </div>
                <div>
                  <img src={icon2} alt="media" />
                  <p>{story.features.icon_2.title}</p>
                  <p className="pr">{story.features.icon_2.subTitle}</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className="section-4 text-center"
          style={{ backgroundColor: 'white' }}
        >
          <Container>
            <h2>{story.trigger.title}</h2>
            <p>{story.trigger.content}</p>
          </Container>
        </div>
        <div className="section-5 text-center">
          <Container>
            <h2>{story.plan.title}</h2>
            <p>{story.plan.content}</p>
          </Container>
        </div>
        <div className="section-6 text-center">
          <Container>
            <h2>{story.carousel.title}</h2>
            <p>{story.carousel.content}</p>
            <Carousel controls={false} fade>
              {story.carousel.image_url.map((item, index) => (
                <Carousel.Item key={index}>
                  <div className="flex justify-center">
                    <img className="car " src={item} alt={index} />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </div>
        <div className="section-7">
          <Container>
            <div className="use-case flex flex-col md:flex-row">
              <div className="flex-shrink-0">
                <img src={story.useCases.avatar_url} alt="avatar" />
              </div>
              <div className="flex flex-col content">
                <p className="flex-grow">{story.useCases.text}</p>
                <div>
                  <span className="company">{story.useCases.company}</span>
                  <span className="company">{story.useCases.department}</span>
                  <span className="name">{story.useCases.name}</span>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="section-8 text-center">
          <Container>
            <h2>{story.actions.title}</h2>
            <div className="flex justify-center">
              <div
                className="grid md:grid-cols-2 md:gap-8 flex-grow"
                style={{ maxWidth: 600 }}
              >
                <button className="primary larger">
                  {story.actions.button_1.title}
                </button>
                <button className="primary larger">
                  {story.actions.button_2.title}
                </button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </BasicLayout>
  )
}

export default SuccessStoryPage
