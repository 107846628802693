import React from 'react'
import { datadogRum } from '@datadog/browser-rum'

import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import naverIcon from '../../../assets/icons/icon-login-naver.svg'
import kakaoIcon from '../../../assets/icons/icon-login-kakao.svg'
// import googleIcon from "../../../assets/icons/icon-login-google.svg"
import Popup from '../../../components/popup'
import AppContext from '../../../services/context'
import { KAKAO_AUTH_URL, NAVER_AUTH_URL, GOOGLE_AUTH_URL } from '../../../services/config'
import { getUserInfo } from '../../../services/api'

const SocialAuth = () => {
  const history = useHistory()
  const { appDispatch } = React.useContext(AppContext)
  const [, setCookie] = useCookies(['jwt_token'])
  const { enqueueSnackbar } = useSnackbar()
  //
  const onSocialPopupEvent = async (e, res) => {
    if (e === 'OPENED') {
      switch (res.name) {
          case 'kakao_auth':
            window.open(KAKAO_AUTH_URL.toString(), res.name)
            break
          case 'naver_auth':
            window.open(NAVER_AUTH_URL.toString(), res.name)
            break
          case 'google_auth':
            window.open(GOOGLE_AUTH_URL.toString(), res.name)
            break
          default:
      }
    } else if (e === 'SUCCESS') {
      //
      if (res.errorCode) {
        if (res.errorCode === '1000') {
          enqueueSnackbar('탈퇴한 회원입니다.', {
            variant: 'error',
          })
          return
        }
      }
      if (res.accessToken) {
        await setCookie('jwt_token', res, {
          path: '/',
          maxAge: 86400 /* 1 day = (60 * 60 * 24) */,
        })
        const result = await getUserInfo(res, { type: 'social_login' })
        await appDispatch({ type: 'updateUserInfo', payload: result })
        datadogRum.setUser({
          id: result.id,
          name: result.userName,
          type: result.userType,
        })
        history.push('/portal/media')
        return
      }
      //
      const method = res.method.toUpperCase()
      console.log(res)
      await appDispatch({
        type: 'updateSignUpMode',
        payload: { type: 'method', content: method },
      })
      await appDispatch({
        type: 'updateSignUpMode',
        payload: {
          type: 'state',
          content: { userId: res.userId, method },
        },
      })
      history.push('/sign-up/social')
    }
  }
  //
  return (
    <>
      <Popup
        className="w-full social-button naver large left-icon"
        url={KAKAO_AUTH_URL.toString()}
        name="naver_auth"
        onEvent={onSocialPopupEvent}
      >
        <img src={naverIcon} alt="error" />
        네이버 로그인 하기
      </Popup>
      <Popup
        className="w-full social-button kakao large left-icon"
        url={NAVER_AUTH_URL.toString()}
        name="kakao_auth"
        onEvent={onSocialPopupEvent}
      >
        <img src={kakaoIcon} alt="error" />
        카카오 로그인 하기
      </Popup>
      {/* <Popup
        className="w-full social-button google large left-icon"
        url="about:blank"
        name="google_auth"
        onEvent={onSocialPopupEvent}
      >
        <img src={googleIcon} alt="error" />
        {"구글 로그인 하기"}
      </Popup> */}
    </>
  )
}

export default SocialAuth
